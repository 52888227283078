/*=====================
   1.2 Typography CSS 
==========================*/
@font-face {
  font-family: "Nunito Sans", sans-serif;
  src: url("../../fonts/NunitoSans-Black.eot");
  src: url("../../fonts/NunitoSans-Black.eot?#iefix") format("embedded-opentype"), url("../../fonts/NunitoSans-Black.woff2") format("woff2"), url("../../fonts/NunitoSans-Black.woff") format("woff"),
    url("../../fonts/NunitoSans-Black.svg#NunitoSans-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans", sans-serif;
  src: url("../../fonts/NunitoSans-BlackItalic.eot");
  src: url("../../fonts/NunitoSans-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../../fonts/NunitoSans-BlackItalic.woff2") format("woff2"),
    url("../../fonts/NunitoSans-BlackItalic.woff") format("woff"), url("../../fonts/NunitoSans-BlackItalic.svg#NunitoSans-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans", sans-serif;
  src: url("../../fonts/NunitoSans-Bold.eot");
  src: url("../../fonts/NunitoSans-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/NunitoSans-Bold.woff2") format("woff2"), url("../../fonts/NunitoSans-Bold.woff") format("woff"),
    url("../../fonts/NunitoSans-Bold.svg#NunitoSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans", sans-serif;
  src: url("../../fonts/NunitoSans-ExtraBold.eot");
  src: url("../../fonts/NunitoSans-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../../fonts/NunitoSans-ExtraBold.woff2") format("woff2"),
    url("../../fonts/NunitoSans-ExtraBold.woff") format("woff"), url("../../fonts/NunitoSans-ExtraBold.svg#NunitoSans-ExtraBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans", sans-serif;
  src: url("../../fonts/NunitoSans-BoldItalic.eot");
  src: url("../../fonts/NunitoSans-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../../fonts/NunitoSans-BoldItalic.woff2") format("woff2"),
    url("../../fonts/NunitoSans-BoldItalic.woff") format("woff"), url("../../fonts/NunitoSans-BoldItalic.svg#NunitoSans-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans", sans-serif;
  src: url("../../fonts/NunitoSans-ExtraBoldItalic.eot");
  src: url("../../fonts/NunitoSans-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("../../fonts/NunitoSans-ExtraBoldItalic.woff2") format("woff2"),
    url("../../fonts/NunitoSans-ExtraBoldItalic.woff") format("woff"), url("../../fonts/NunitoSans-ExtraBoldItalic.svg#NunitoSans-ExtraBoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans", sans-serif;
  src: url("../../fonts/NunitoSans-ExtraLight.eot");
  src: url("../../fonts/NunitoSans-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../../fonts/NunitoSans-ExtraLight.woff2") format("woff2"),
    url("../../fonts/NunitoSans-ExtraLight.woff") format("woff"), url("../../fonts/NunitoSans-ExtraLight.svg#NunitoSans-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans", sans-serif;
  src: url("../../fonts/NunitoSans-ExtraLightItalic.eot");
  src: url("../../fonts/NunitoSans-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("../../fonts/NunitoSans-ExtraLightItalic.woff2") format("woff2"),
    url("../../fonts/NunitoSans-ExtraLightItalic.woff") format("woff"), url("../../fonts/NunitoSans-ExtraLightItalic.svg#NunitoSans-ExtraLightItalic") format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans", sans-serif;
  src: url("../../fonts/NunitoSans-Italic.eot");
  src: url("../../fonts/NunitoSans-Italic.eot?#iefix") format("embedded-opentype"), url("../../fonts/NunitoSans-Italic.woff2") format("woff2"), url("../../fonts/NunitoSans-Italic.woff") format("woff"),
    url("../../fonts/NunitoSans-Italic.svg#NunitoSans-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans", sans-serif;
  src: url("../../fonts/NunitoSans-Light.eot");
  src: url("../../fonts/NunitoSans-Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/NunitoSans-Light.woff2") format("woff2"), url("../../fonts/NunitoSans-Light.woff") format("woff"),
    url("../../fonts/NunitoSans-Light.svg#NunitoSans-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans", sans-serif;
  src: url("../../fonts/NunitoSans-Regular.eot");
  src: url("../../fonts/NunitoSans-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/NunitoSans-Regular.woff2") format("woff2"),
    url("../../fonts/NunitoSans-Regular.woff") format("woff"), url("../../fonts/NunitoSans-Regular.svg#NunitoSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans", sans-serif;
  src: url("../../fonts/NunitoSans-LightItalic.eot");
  src: url("../../fonts/NunitoSans-LightItalic.eot?#iefix") format("embedded-opentype"), url("../../fonts/NunitoSans-LightItalic.woff2") format("woff2"),
    url("../../fonts/NunitoSans-LightItalic.woff") format("woff"), url("../../fonts/NunitoSans-LightItalic.svg#NunitoSans-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans", sans-serif;
  src: url("../../fonts/NunitoSans-SemiBold.eot");
  src: url("../../fonts/NunitoSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("../../fonts/NunitoSans-SemiBold.woff2") format("woff2"),
    url("../../fonts/NunitoSans-SemiBold.woff") format("woff"), url("../../fonts/NunitoSans-SemiBold.svg#NunitoSans-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito Sans", sans-serif;
  src: url("../../fonts/NunitoSans-SemiBoldItalic.eot");
  src: url("../../fonts/NunitoSans-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../../fonts/NunitoSans-SemiBoldItalic.woff2") format("woff2"),
    url("../../fonts/NunitoSans-SemiBoldItalic.woff") format("woff"), url("../../fonts/NunitoSans-SemiBoldItalic.svg#NunitoSans-SemiBoldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

body {
  font-family: $font-1;
}
ul {
  list-style: none;
}
li {
  font-size: 14px;
  color: $title-color;
}

p {
  font-size: 14px;
  line-height: 1.5;
  color: $title-color;
}

a {
  transition: 0.5s ease;
  text-decoration: none;
  color: $title-color;

  &:hover {
    text-decoration: none;
    transition: 0.5s ease;
    color: currentColor;
  }
  &:focus {
    outline: none;
  }
}

:focus {
  outline: none;
}

.form-control {
  font-size: 14px;
  color: $title-color;
  [dir="rtl"] & {
    text-align: right;
  }
  &:focus {
    box-shadow: none;
  }
}

h1 {
  font-size: 38px;
  font-weight: 700;
  line-height: 33px;
  text-transform: capitalize;
  margin: 0;
  color: $title-color;
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  margin: 0;
  color: $title-color;
}

h3 {
  font-size: 20px;
  line-height: 16px;
  margin: 0;
  color: $title-color;
}

h4 {
  font-size: 18px;
  line-height: 25px;
  margin: 0;
  color: $title-color;
}

h5 {
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  font-weight: normal;
  color: $title-color;
}

h6 {
  font-size: 14px;
  line-height: 19px;
  font-weight: normal;
  margin: 0;
  color: $title-color;
}

/// Font Size ///
$theme-font-size: (
  xs: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320))),
  sm: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))),
  md: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320))),
  lg: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320))),
  xl: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320))),
  xxl: calc(24px + (30 - 24) * ((100vw - 320px) / (1920 - 320))),
);
@each $size, $value in $theme-font-size {
  .font-#{$size} {
    font-size: $value;
  }
}

.title-2 {
  line-height: 22px;
  font-weight: 600;
  font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
}
.pera {
  font-weight: normal;
  line-height: 24px;
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}
.title-color {
  color: $title-color;
}
.content-color {
  color: $content-color;
}
.font-success {
  color: $theme-success !important;
}
.font-danger {
  color: $theme-danger !important;
}
