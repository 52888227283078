/*=====================
  4.3 E-commerce Pages CSS
==========================*/

/// E-Home Page Css Start ///
.e-home {
  h2 {
    margin-top: -3px;
  }
  .search-box {
    @include search-box();
  }
  .banner-section {
    [dir="rtl"] & {
      direction: ltr;
    }
    .slick-track {
      padding: 1px 0;
      margin-left: -62px;
      @media only screen and (max-width: 475px) {
        margin-left: -32px;
      }

      @media only screen and (max-width: 375px) {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    .slick-list {
      @media only screen and (max-width: 375px) {
        margin-left: -7px;
        margin-right: -7px;
      }
    }
    .slick-slide {
      padding: 0 12px !important;
      @media only screen and (max-width: 375px) {
        padding: 0 7px !important;
      }
    }

    .banner-box {
      width: 100%;
      padding: 12px;
      border: $card-border;
      border-radius: 10px;
      box-shadow: $box-shadow;
      [dir="rtl"] & {
        direction: rtl;
      }
      .banner-img {
        position: relative;
        border: $card-border;
        border-radius: 10px;
        box-shadow: $box-shadow;
      }
      .content-box {
        cursor: pointer;
        padding-top: 12px;
        p {
          margin-bottom: 11px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: $content-color;
          span {
            &:last-child {
              color: $white;
              font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
              background-color: $theme-dark-orange;
              border-radius: 3px;
              line-height: calc(21px + (23 - 22) * ((100vw - 320px) / (1920 - 320)));
              padding: 0 6px;
            }
          }
        }
        & > span {
          display: block;
        }
        h3 {
          font-weight: 600;
          margin-bottom: calc(3px + (6 - 3) * ((100vw - 320px) / (1920 - 320)));
        }
        .btn {
          margin-top: 15px;
          padding: calc(6px + (7 - 6) * ((100vw - 320px) / (1920 - 320))) calc(22px + (22 - 22) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }
  }
  .categories {
    ul {
      display: flex;
      align-items: center;
      border: none;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      li {
        border-radius: 5px;
        padding: 10px;
        a {
          img {
            min-width: 32px;
            min-height: 32px;
          }
        }
        &:not(:last-child) {
          margin-right: 15px;
          [dir="rtl"] & {
            margin-left: 15px;
            margin-right: unset;
          }
        }
      }
    }
  }
  .isotopeContainer {
    margin-bottom: -20px;
  }
  .product-section {
    .row {
      > div {
        @media only screen and (max-width: 375px) {
          width: 100%;
        }
      }
    }
  }
  .recently-section {
    .wrapper {
      [dir="rtl"] & {
        direction: ltr;
      }
      .slick-track {
        margin-left: -48px;
        padding: 1px 0;
        @media only screen and (max-width: 475px) {
          margin-left: -20px;
        }

        @media only screen and (max-width: 375px) {
          margin-left: 0px;
          margin-right: 0px;
        }
      }
      .slick-slide {
        @media only screen and (max-width: 375px) {
          padding: 10px 15px !important;
        }
      }
      .slick-list {
        @media only screen and (max-width: 375px) {
          margin-right: -10px;
        }
      }

      .recently-box {
        border: $card-border;
        box-shadow: $box-shadow;
        border-radius: 6px;
        padding: 10px;
        [dir="rtl"] & {
          direction: rtl;
        }
        &:not(:last-child) {
          margin-right: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
        }
        .media {
          margin-left: 14px;
          [dir="rtl"] & {
            margin-right: 14px;
            margin-left: unset;
          }
          img {
            position: relative;
            width: 40px;
            height: 40px;
            z-index: 2;
          }
          .img-box {
            position: relative;
            svg {
              fill: $theme-yellow;
              position: absolute;
              width: 160%;
              height: 150%;
              z-index: 1;
              top: 50%;
              left: 45%;
              transform: translate(-50%, -50%);
            }
          }
          > a {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 3px;
            border-radius: 100%;
            background-image: $dark-gradient;
            [dir="rtl"] & {
              margin-right: auto;
              margin-left: unset;
            }
            svg {
              width: 20px;
              height: 20px;
              stroke: $white;
              [dir="rtl"] & {
                transform-origin: center;
                transform: rotate(180deg);
              }
            }
          }
          .media-body {
            margin-left: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
            [dir="rtl"] & {
              margin-left: unset;
              margin-right: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
            }
            h3 {
              font-weight: 600;
              margin-bottom: 7px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              width: 157px;
            }
            > span {
              display: flex;
              align-items: center;
              font-size: 14px;
              svg {
                fill: $theme-yellow-dark;
                stroke: $theme-yellow-dark;
                width: 16px;
                height: 16px;
                margin-right: 9px;
                [dir="rtl"] & {
                  margin-left: 9px;
                  margin-right: unset;
                }
              }
              span {
                color: $theme-danger;
                font-weight: 600;
                margin-left: 9px;
                [dir="rtl"] & {
                  margin-left: unset;
                  margin-right: 9px;
                }
              }
            }
          }
        }
      }
    }
  }
}
/// Home Page Css End ///

/// Isotope Masonry Css Star ///
.isotopeContainer {
  .isotopeSelector {
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
    [dir="rtl"] & {
      float: right;
    }
    @media only screen and (max-width: 375px) {
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
    }
  }
}
/// Isotope Masonry Css End ///

/// Shop Page Start ///
.shop-page {
  .search-side-icon {
    @include search-side-icon();
  }
  .product-section {
    h2 {
      font-size: calc(22px + (25 - 22) * ((100vw - 320px) / (1920 - 320)));
      line-height: 30px;
      span {
        display: block;
      }
    }
  }
  .row {
    > div {
      @media only screen and (max-width: 375px) {
        width: 100%;
      }
      &:first-of-type {
        h2 {
          @media only screen and (max-width: 375px) {
            margin-top: 5px;
            margin-bottom: -10px;
          }
          span {
            @media only screen and (max-width: 375px) {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
/// Shop Page End ///

/// product-main Page Star ///
.product-main {
  .product-banner-slider {
    [dir="rtl"] & {
      direction: ltr;
    }
  }
  .banner-box {
    border: $card-border;
    box-shadow: $box-shadow;
    border-radius: 9px;
    padding: 5px;
    position: relative;
    margin-right: 10px;
    margin-left: 10px;
    .bg-size {
      &:before {
        padding-top: 45%;
      }
    }
    > div {
      border-radius: 9px;
      background-color: $theme-yellow;
    }
    > img {
      position: absolute;
      width: 40%;
      top: 50%;
      left: 23%;
      transform: translate(-50%, -50%);
    }
  }
  .slick-list {
    margin-left: -8px;
    margin-right: -8px;
    padding: 2px 0;
  }
  .slick-dotted {
    &.slick-slider {
      margin-bottom: 20px;
    }
  }
  .slick-dots {
    text-align: center;
    position: relative;
    bottom: 0px;
    left: 0;
    right: 0;
    margin-top: calc(-22px + (-30 + 22) * ((100vw - 320px) / (1920 - 320)));
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      position: relative;
      display: inline-block;
      height: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
      width: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
      margin: 0 calc(3px + (5 - 3) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 100%;
      background-color: $white;
      border: $card-border;
      padding: 0;
      cursor: pointer;
      button {
        opacity: 0;
      }
    }

    .slick-active {
      background-color: $title-color !important;
      height: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
      width: calc(22px + (30 - 22) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 20px;
    }
  }
  .product-spec {
    text-align: center;
    @include dashed-border-bottom();
    .rating-box {
      margin-top: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
      display: flex;
      align-items: center;
      justify-content: center;
      .star-box {
        @include start-box();
        margin-top: 0;
      }
      span {
        margin-left: 10px;
        [dir="rtl"] & {
          margin-left: unset;
          margin-right: 10px;
        }
      }
    }
    h3 {
      font-weight: 600;
    }
    p {
      margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    }
    .select-group {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
      @media only screen and (max-width: 375px) {
        flex-direction: column;
      }
      .size-Select {
        border: $card-border;
        box-shadow: $box-shadow;
        padding: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320))) calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 8px;
        display: flex;
        align-items: center;

        &:not(:first-of-type) {
          margin-left: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
          @media only screen and (max-width: 375px) {
            margin-left: 0px;
            margin-top: 10px;
          }
          [dir="rtl"] & {
            margin-left: unset;
            margin-right: calc(13px + (20 - 13) * ((100vw - 320px) / (1920 - 320)));
          }
          @media only screen and (max-width: 375px) {
            [dir="rtl"] & {
              margin-right: 0;
            }
          }
        }
        svg {
          margin-left: auto;
          width: 20px;
          height: 20px;
          [dir="rtl"] & {
            transform-origin: center;
            transform: rotate(180deg);
          }
        }
        .size-box {
          display: flex;
          align-items: center;
          span {
            margin-left: 3px;
            margin-right: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
            [dir="rtl"] & {
              margin-left: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
              margin-right: unset;
            }
            &:first-of-type {
              width: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
              height: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
              display: flex;
              align-items: center;
              justify-content: center;
              background-image: $dark-gradient;
              color: $white;
              border-radius: 100%;
              font-size: 13px;
            }
          }
        }
        &:last-child {
          span {
            &:first-of-type {
              font-size: 0;
              background: $theme-orange;
            }
          }
        }
      }
    }
  }
  .detail-tabs {
    @include dashed-border-bottom();
    .tab-content {
      .tab-pane {
        p {
          text-transform: lowercase;
        }
        table {
          tr {
            td {
              font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
              color: $content-color;
              &:last-child {
                color: $title-color;
                text-align: right;
                [dir="rtl"] & {
                  text-align: left;
                }
              }
            }
          }
        }
      }

      .review-box {
        padding: 30px 0;
        &:not(:last-child) {
          border-bottom: 1px solid rgba($border-color, 0.1);
        }
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0px;
        }
        .media {
          img {
            width: 45px;
            height: 45px;
            border-radius: 100%;
          }
          .media-body {
            h3 {
              font-weight: 600;
            }
            .star-box {
              @include start-box();
            }
          }
        }
        p {
          margin-top: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
        }
        .like-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          > span {
            &:first-of-type {
              font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
              padding: 4px 15px;
              background-color: $theme-yellow;
              border-radius: 100px;
            }
            &:last-child {
              display: flex;
              align-items: center;
              span {
                display: flex;
                align-items: center;
                color: $content-color;
                &:not(:last-child) {
                  margin-right: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
                  [dir="rtl"] & {
                    margin-left: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
                    margin-right: unset;
                  }
                }

                svg {
                  width: 20px;
                  height: 20px;
                  margin-right: 8px;
                  [dir="rtl"] & {
                    margin-left: 8px;
                    margin-right: unset;
                  }
                }
              }
            }
          }
        }
      }
      .videobox {
        position: relative;
        padding: 0 !important;
        .video-iframe {
          width: 100%;
          height: calc(170px + (320 - 170) * ((100vw - 320px) / (1920 - 320)));
          border-radius: 15px;
          display: none;
        }
        .video-thumb {
          width: 100%;
          border-radius: 15px;
        }
        .play {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          background-image: $dark-gradient;
          svg {
            width: 25px;
            stroke: $white;
            height: 25px;
            margin-left: 4px;
          }
        }
      }
    }
  }
  .recently-viewed {
    [dir="rtl"] & {
      direction: ltr;
    }
    .product-card {
      .img-box {
        svg {
          width: calc(19px + (21 - 19) * ((100vw - 320px) / (1920 - 320)));
          height: calc(19px + (21 - 19) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }
    .slick-track {
      padding: 1px 0;
      margin-left: -48px;

      @media only screen and (max-width: 567px) {
        margin-left: -30px;
      }
      @media only screen and (max-width: 475px) {
        margin-left: 0px;
      }
      @media only screen and (max-width: 390px) {
        margin-left: -30px;
      }
    }
    .slick-list {
      @media only screen and (max-width: 435px) {
        margin-left: -8px;
        margin-right: -8px;
      }
    }
    .slick-slide {
      padding: 0 8px !important;
      @media only screen and (max-width: 567px) {
        padding: 0 8px !important;
      }
    }

    .slick-dots {
      margin-top: 27px;
    }
  }
}
/// product-main Page End ///

/// Cart Page Start ///
.cart-page {
  .cart-product {
    .cart-item {
      padding: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
      border: $card-border;
      box-shadow: $box-shadow;
      position: relative;
      border-radius: 9px;
      &:not(:last-child) {
        margin-bottom: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
      }
      > span,
      .media .cart-img span {
        position: absolute;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: $dark-gradient;
        border-radius: 100%;
        svg {
          stroke: $white;
          width: 16px;
          height: 16px;
        }
      }
      > span {
        z-index: 1;
        right: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
        top: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
        cursor: pointer;
        [dir="rtl"] & {
          right: unset;
          left: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
        }
      }
      .media {
        align-items: flex-start;
        .cart-img {
          position: relative;
          flex: 0 0 30%;
          display: inline-block;
          img {
            width: 100%;
            border-radius: 9px;
          }
          span {
            right: 10px;
            bottom: 10px;
            padding: 5px;
            [dir="rtl"] & {
              right: unset;
              left: 10px;
            }
            .fill {
              fill: $white;
            }
          }
        }
        .media-body {
          h3 {
            font-weight: 600;
            margin-bottom: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
            @media only screen and (max-width: 375px) {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 134px;
            }
          }
          > span {
            color: $theme-danger;
            font-weight: 600;
            display: block;
            margin-bottom: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
          }
          .quantity {
            display: flex;
            align-items: center;
            span {
              margin-right: 25px;
              font-weight: 600;
              text-transform: uppercase;
              [dir="rtl"] & {
                margin-left: 25px;
                margin-right: unset;
              }
            }
            .btn {
              padding: 2px 10px;
              display: flex;
              align-items: center;
              width: fit-content;
              box-shadow: none;
              input {
                width: 33px;
                line-height: 0;
                text-align: center;
                background-color: transparent;
                box-shadow: none;
                border: none;
                color: $white;
                font-size: 12px;
              }
              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
    > .btn {
      display: flex;
      padding: 10px calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        [dir="rtl"] & {
          margin-left: 10px;
          margin-right: unset;
        }
      }
      svg {
        width: 22px;
        height: 22px;
        margin-left: auto;
        [dir="rtl"] & {
          margin-right: auto;
          margin-left: unset;
          transform-origin: center;
          transform: rotate(180deg);
        }
      }
    }
  }
  .order-detail {
    @include order-summery();
    h3 {
      margin-bottom: 6px;
    }
  }
}
/// Cart Page End ///

/// Checkout Page Start ///
.checkout-page {
  .address-section,
  .payment-card-section {
    .address-box {
      padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320))) 0;
      &:first-of-type {
        padding-top: 0;
      }
      &:last-of-type {
        padding-bottom: 0;
      }
      &:not(:last-of-type) {
        @include dashed-border-bottom();
      }
      .media {
        align-items: flex-start;
        > span {
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          border: $card-border;
          span {
            transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
            transform: scale(0, 0);
            width: calc(12px + (15 - 12) * ((100vw - 320px) / (1920 - 320)));
            height: calc(12px + (15 - 12) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 100%;
            background-image: $dark-gradient;
          }
        }
        .media-body {
          width: calc(100% - 25px);
          position: relative;
          h2 {
            margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
          }
          p {
            width: 80%;
            margin-bottom: 0;
          }
          span {
            position: absolute;
            right: 0px;
            top: 0;
            background-color: $theme-blue;
            border-radius: 100px;
            padding: 4px calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 600;
            font-size: 12px;
            [dir="rtl"] & {
              right: unset;
              left: 10px;
            }
          }
        }
      }
      &.active {
        .media {
          > span {
            span {
              transform: scale(1, 1);
            }
          }
        }
      }
    }
    .btn {
      display: block;
      margin-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    }
  }
  .banner-section {
    padding-top: calc(65px + (120 - 65) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;
    img {
      width: 30%;
    }
    h2 {
      line-height: 27px;
      margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
    }
  }
  .order-detail {
    background-color: $gray-light;
    padding: 20px 12px;
    border-radius: 6px;
    @include order-summery();
    margin-top: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
    h3 {
      margin-bottom: 6px;
    }
    ul {
      li {
        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }
  }
  .tab-content {
    > div {
      h3 {
        color: $title-color;
        font-weight: 600;
        margin-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
      }
    }
    .card-box {
      border: $card-border;
      box-shadow: $box-shadow;
      padding: 10px;
      border-radius: 8px;
      &:not(:last-of-type) {
        margin-bottom: calc(17px + (25 - 17) * ((100vw - 320px) / (1920 - 320)));
      }
      .media {
        img {
          width: 40px;
          height: auto;
        }
        .media-body {
          position: relative;
          width: 100%;
          > span {
            line-height: 22px;
            font-weight: 600;
            &:last-of-type {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 10px;
              padding: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100%;
              border: $card-border;
              [dir="rtl"] & {
                right: unset;
                left: 10px;
              }
              span {
                transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
                transform: scale(0, 0);
                width: 12px;
                height: 12px;
                border-radius: 100%;
                background-image: $dark-gradient;
              }
            }
          }
        }
      }
      &.active {
        .media {
          .media-body {
            > span {
              &:last-of-type {
                span {
                  transform: scale(1, 1);
                }
              }
            }
          }
        }
      }
    }
    .custom-form {
      .input-box {
        margin-bottom: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
        label {
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        }
      }
      button {
        width: 100%;
      }
    }
    .net-banking {
      .row {
        > div {
          @media only screen and (max-width: 567px) {
            width: 100%;
          }
        }
      }
    }
  }
}
/// Checkout Page End ///

/// Wishlist Page Start ///
.wishlist-page {
  .cart-product {
    .cart-item {
      .media {
        .media-body {
          .btn {
            padding: 2px 14px;
            font-size: 12px;
            font-weight: 400;
            box-shadow: none;
          }
          > span {
            margin-bottom: 7px;
          }
        }
      }
    }
  }
}
/// Wishlist Page End ///

/// Coupons Page Start ///
.coupons-page {
  .search-box {
    @include search-box();
  }
  .coupon-section {
    .coupon-box {
      padding: 20px 0;
      &:first-of-type {
        padding-top: 0;
      }
      &:last-of-type {
        padding-bottom: 0;
      }
      &:not(:last-of-type) {
        border-bottom: 1px solid rgba($border-color, 0.1);
      }
      > div {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        h2 {
          line-height: 21px;
        }
        span {
          &:first-of-type {
            font-size: 14px;
            padding: 2px 8px;
            background-color: $theme-blue;
            color: $content-color;
            border-radius: 5px;
            margin-left: 10px;
            [dir="rtl"] & {
              margin-left: unset;
              margin-right: 10px;
            }
          }
        }
        a {
          margin-left: auto;
          color: $theme-danger;
          font-size: 16px;
          [dir="rtl"] & {
            margin-left: unset;
            margin-right: auto;
          }
        }
      }
      p {
        margin-bottom: 8px;
      }
      span {
        color: $theme-success;
        font-size: 16px;
      }
    }
  }
}
/// Coupons Page End ///

/// Order Tracking Page Start ///
.order-tracking-page {
  .banner-box {
    @include banner();
    overflow: hidden;
    padding: 0;
    img {
      height: 80%;
    }
  }
  .track {
    ul {
      li {
        padding: 20px 0;
        position: relative;
        &:first-of-type {
          padding-top: 0;
        }
        &:last-of-type {
          padding-bottom: 0;
        }
        .media {
          > span {
            width: 44px;
            height: 44px;
            border-radius: 100%;
            background-image: $dark-gradient;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              width: 24px;
              height: 24px;
              stroke: $white;
            }
          }
          .media-body {
            h3 {
              font-weight: 600;
              line-height: 22px;
            }
            span {
              color: $content-color;
            }
          }
        }
        .track-border {
          position: absolute;
          left: 20px;
          margin-top: 9px;
          [dir="rtl"] & {
            left: unset;
            right: 20px;
          }
        }

        &:last-of-type {
          .track-border {
            display: none;
          }
        }
        &.in-progress {
          opacity: 0.5;
          .track-border {
            opacity: 0.5;
          }
        }
      }
    }
  }
  .shopping-detail {
    background-color: $gray-light;
    padding: 20px 12px 30px 12px;
    position: relative;
    > div {
      h4 {
        font-weight: 600;
        color: $title-color;
        font-size: 16px;
        margin-bottom: 3px;
      }
      p {
        font-size: 16px;
        color: $content-color;
        line-height: 30px;
        margin: 0;
      }
      span {
        font-size: 16px;
      }
    }
    .shape2-left {
      left: 30px;
    }
    .shape2-right {
      right: 21px;
    }
  }
  .order-detail {
    background-color: $gray-light;
    padding: 30px 12px 20px 12px;
    border-radius: 6px;
    margin-top: 22px;
    @include order-summery();
    ul {
      li {
        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }
  }
}
/// Order Tracking Page End ///

/// Order History Page Star ///
.order-history-page {
  .search-side-icon {
    @include search-side-icon();
  }
  .tab-content {
    .tab-pane {
      .order-box {
        border: $card-border;
        box-shadow: $box-shadow;
        border-radius: 10px;
        padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
        &:not(:first-of-type) {
          margin-top: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
        }
        .media {
          align-items: flex-start;
          > div {
            flex: 0 0 30%;
            img {
              width: 100%;
              border-radius: 10px;
            }
          }
          .media-body {
            flex: 1 1 auto;
            display: flex;
            align-items: flex-start;
            @media only screen and (max-width: 475px) {
              flex-direction: column;
              > a {
                margin-left: 0;
                margin-top: 8px;
                margin-bottom: -4px;
                [dir="rtl"] & {
                  margin-left: unset;
                  margin-right: 0;
                }
              }
            }
            > div {
              h2 {
                margin-top: -4px;
                margin-bottom: 4px;
              }
              span {
                color: $content-color;
              }
              .star-box {
                @include start-box();
                svg {
                  width: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                  height: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                }
              }
            }
            > a {
              margin-top: -4px;
              margin-left: auto;
              color: $theme-danger;
              [dir="rtl"] & {
                margin-left: unset;
                margin-right: auto;
              }
              @media only screen and (max-width: 475px) {
                margin-left: 0;
                margin-top: 8px;
                [dir="rtl"] & {
                  margin-left: unset;
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
/// Order History Page End ///
