/*=====================
 2.1 Accordian CSS 
==========================*/

/// Accordian Style 1 Start ///
.accordian-style1 {
    .accordion-item {
        border: none;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        .accordion-button {
            border: $card-border;
            background-color: $white;
            box-shadow: none;
            color: $title-color;
            border-radius: 5px;
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            &,
            &:focus,
            &:not(.collapsed) {
                &::after {
                    background-image: url(../images/icon/chevron-down.svg);
                }
            }
            &::after {
                background-image: url(../images/icon/chevron-down.svg);
                background-color: $theme-blue;
                padding: 12px;
                background-position: center;
                border-radius: 4px;
            }
            @media only screen and (max-width: 375px) {
                span {
                    overflow-x: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 220px;
                }
            }
        }
        .accordion-body {
            padding: 10px 0;
            color: $content-color;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        }
    }
}
/// Accordian Style 1 End ///

/// Accordian Style 2 Start ///
.accordian-style2 {
    @extend .accordian-style1;
    .accordion-item {
        .accordion-button {
            background-image: $dark-gradient;
            color: $white;
            &,
            &:focus,
            &:not(.collapsed) {
                color: $white;
            }
            &::after {
                background-color: $white-light;
            }
        }
    }
}
/// Accordian Style 2 End ///

/// Accordian Style 3 Start ///
.accordian-style3 {
    @extend .accordian-style1;
    .accordion-item {
        .accordion-button {
            border: none;
            border-left: 2px solid;
            border-radius: 0;
            [dir="rtl"] & {
                border-right: 2px solid;
                border-left: unset;
            }
            &:not(.collapsed),
            &:focus {
                &::after {
                    background-image: url(../images/icon/minus.svg);
                }
            }
            &::after {
                background-color: transparent;
                transition: all 0.3s;
            }
            &.collapsed {
                &::after {
                    background-image: url(../images/icon/plus.svg);
                }
            }
        }
        &:nth-of-type(3n + 1) {
            h2 {
                .accordion-button {
                    &,
                    &:focus,
                    &:not(.collapsed) {
                        background-color: rgba($theme-yellow, 0.5);
                        border-color: darken($theme-yellow, 20%);
                    }
                }
            }
        }
        &:nth-of-type(3n + 2) {
            h2 {
                .accordion-button {
                    &,
                    &:focus,
                    &:not(.collapsed) {
                        background-color: rgba($theme-green, 0.5);
                        border-color: darken($theme-green, 20%);
                    }
                }
            }
        }
        &:nth-of-type(3n + 3) {
            h2 {
                .accordion-button {
                    &,
                    &:focus,
                    &:not(.collapsed) {
                        background-color: rgba($theme-purple, 0.5);
                        border-color: darken($theme-purple, 20%);
                    }
                }
            }
        }
    }
}
/// Accordian Style 3 End ///
