/*=====================
  3.3 Footer CSS
==========================*/

/// Footer Menu Default Style Start ///
.footer-wrap {
    margin: 0 auto;
    max-width: 600px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    background-color: transparent;
    z-index: 3;
    .footer {
        background-image: $dark-gradient;
        border-radius: 100px;
        padding: calc(17px + (15 - 17) * ((100vw - 320px) / (1920 - 320))) 0;
        box-shadow: $btn-shadow;
        > ul {
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            li {
                .footer-link {
                    position: relative;
                    svg {
                        width: 24px;
                        height: 24px;
                        stroke: $white-light;
                    }
                    &::before {
                        position: absolute;
                        content: "";
                        display: none;
                        width: 4px;
                        right: 10px;
                        height: 4px;
                        top: -10px;
                        border-radius: 100%;
                        background-color: $white-light;
                    }
                    &.active {
                        &::before {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    /// Message Type Footer ///
    &.type-message {
        .footer {
            padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) 0;
            textarea {
                background-color: transparent;
                color: $white;
                border: none;
                padding-left: 0;
                overflow: hidden;
                resize: none;
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                &::placeholder {
                    color: $white;
                }
            }
            padding-left: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
            padding-right: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
            display: flex;
            align-items: center;
            ul {
                position: absolute;
                right: calc(34px + (47 - 34) * ((100vw - 320px) / (1920 - 320)));
                [dir="rtl"] & {
                    right: unset;
                    left: calc(34px + (47 - 34) * ((100vw - 320px) / (1920 - 320)));
                }
                li {
                    &:not(:last-child) {
                        margin-right: 10px;
                        [dir="rtl"] & {
                            margin-left: 10px;
                            margin-right: unset;
                        }
                    }
                    a {
                        svg {
                            stroke: $white;
                            width: calc(20px + (24 - 24) * ((100vw - 320px) / (1920 - 320)));
                            height: calc(20px + (24 - 24) * ((100vw - 320px) / (1920 - 320)));
                        }
                    }
                }
                .dropup {
                    .dropdown-toggle {
                        &::after {
                            display: none;
                        }
                    }
                    .dropdown-menu {
                        background-color: transparent;
                        transform: translate(-12px, -36.4722px) !important;
                        min-width: unset;
                        border: none;
                        li {
                            &:not(:last-of-type) {
                                margin-bottom: 8px;
                            }
                            a {
                                padding: 10px;
                                width: 40px;
                                height: 40px;
                                background-image: $dark-gradient;
                                border-radius: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }

        .emojionearea {
            &.form-control {
                height: 31px;
                background-image: $dark-gradient !important;
                border: none;
                color: $white;
            }
            &.focused {
                box-shadow: none;
            }
            .emojionearea-editor {
                color: $white;
                display: block;
                height: auto;
                overflow-y: hidden;
                min-height: 31px;
                max-height: 31px;
                scroll-snap-type: none;
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320))) !important;
                padding: 3px 95px 3px 0px;
                line-height: 1.42857143;
                font-size: inherit;
                border: 0;
                cursor: text;
                margin-right: 1px;
                border-radius: 0;
                box-shadow: none;
                [dir="rtl"] & {
                    padding: 3px 0px 3px 95px;
                }
            }
            .emojionearea-button {
                right: 35px;
                opacity: 0;
                [dir="rtl"] & {
                    right: unset;
                    left: 35px;
                }
                &.active {
                    + .emojionearea-picker-position-top {
                        margin-top: -273px;
                        right: 12px;
                        [dir="rtl"] & {
                            right: unset;
                            left: 12px;
                        }
                    }
                }
            }
            .emojionearea-picker {
                .emojionearea-search-panel {
                    display: none;
                }
                .emojionearea-filters {
                    .emojionearea-filter {
                        height: 40px;
                        width: 29px;
                        padding: 7px 1px 0;
                    }
                }
            }
        }
    }

    /// Add to cart Footer ///
    &.addcart {
        .footer {
            padding-left: calc(20px + (34 - 20) * ((100vw - 320px) / (1920 - 320)));
            padding-right: calc(7px + (11 - 7) * ((100vw - 320px) / (1920 - 320)));
            padding-top: calc(7px + (11 - 7) * ((100vw - 320px) / (1920 - 320)));
            padding-bottom: calc(7px + (11 - 7) * ((100vw - 320px) / (1920 - 320)));
            [dir="rtl"] & {
                padding-right: calc(20px + (34 - 20) * ((100vw - 320px) / (1920 - 320)));
                padding-left: calc(7px + (11 - 7) * ((100vw - 320px) / (1920 - 320)));
            }
            ul {
                justify-content: space-between;
                li {
                    display: flex;
                    align-items: center;
                    &:first-of-type {
                        svg {
                            width: 24px;
                            height: 24px;
                            stroke: $white;
                        }
                    }
                    input {
                        border: none;
                        box-shadow: none;
                        text-align: center;
                        color: $white;
                        background-color: transparent;
                        font-size: 20px;
                    }
                    a {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        text-transform: unset;
                        svg {
                            width: 18px;
                            height: 18px;
                            margin-right: 10px;
                            [dir="rtl"] & {
                                margin-left: 10px;
                                margin-right: unset;
                            }
                        }
                        @media only screen and (max-width: 400px) {
                            span {
                                display: none;
                            }
                        }
                    }
                    .btn {
                        padding: calc(7px + (11 - 7) * ((100vw - 320px) / (1920 - 320))) calc(20px + (35 - 20) * ((100vw - 320px) / (1920 - 320)));
                    }
                    > span {
                        font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
                        color: $white-light;
                    }
                }
            }
        }
    }
}
/// Footer Menu Default Style End ///

/// Footer Menu Style 1 Start ///
.footer-style1 {
    .footer {
        background: $white;
        box-shadow: none;
        padding: 0;
        ul {
            padding: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320))) 0;
            li {
                .footer-link {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    svg {
                        stroke: $title-color;
                        width: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
                        height: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
                        &.fill {
                            fill: $white;
                        }
                    }
                    .tag {
                        font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
                        text-transform: uppercase;
                        margin-top: 4px;
                    }
                    &.active {
                        &::before {
                            display: none;
                        }
                        svg {
                            stroke: $theme-danger;
                        }
                        .tag {
                            color: $theme-danger;
                        }
                    }
                }
            }
        }
    }
}
/// Footer Menu Style 1 End ///

/// Footer Menu Style 2 Start ///
.footer-style2 {
    @extend .footer-style1;
    .footer {
        ul {
            li {
                .footer-link {
                    flex-direction: row;
                    padding: 8px 14px;
                    border-radius: 100px;
                    align-items: center;
                    .tag {
                        display: none;
                        margin: 0;
                    }
                    &.active {
                        background-image: $dark-gradient;
                        svg {
                            stroke: $white;
                            margin-right: 10px;
                            [dir="rtl"] & {
                                margin-left: 10px;
                                margin-right: 10px;
                            }
                        }
                        .tag {
                            display: inline-block;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}
/// Footer Menu Style 2 End ///

/// Footer Menu Style 3 Start ///
.footer-style3 {
    @extend .footer-style1;
    .footer {
        ul {
            li {
                .footer-link {
                    &.active {
                        background-color: rgba($theme-yellow, 0.6);
                        padding: 8px 12px;
                        border-radius: 5px;
                        svg {
                            stroke: darken($theme-yellow, 40%);
                        }
                        .tag {
                            color: darken($theme-yellow, 40%);
                        }
                    }
                }
            }
        }
    }
}
/// Footer Menu Style 3 End ///

/// Footer Menu Style 4 Start ///
.footer-style4 {
    @extend .footer-style1;
    .footer {
        ul {
            padding: 0;
            li {
                .footer-link {
                    border-top: 2px solid transparent;
                    border-bottom: 2px solid transparent;
                    padding: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320))) 0;
                    &.active {
                        border-top-color: $theme-danger;
                    }
                }
            }
        }
    }
}
/// Footer Menu Style 4 End ///
