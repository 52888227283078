/*=====================
  2.4 Buttons  CSS
==========================*/

.btn {
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    text-transform: capitalize;
    [dir="rtl"] & {
        text-align: center !important;
    }
    &.btn-round {
        border-radius: 100px;
        background-image: $dark-gradient;
        color: $white-light;
        box-shadow: $btn-shadow;
        border: $card-border;
        padding: 11px 40px;
        font-weight: 700;
    }
    &.btn-square {
        border-radius: 8px;
        background-image: $dark-gradient;
        color: $white-light;
        box-shadow: none;
        padding: 11px 40px;
        font-weight: 700;
        border: $card-border;
        &:focus {
            outline: none;
        }
    }
    &.call-btn {
        padding: 10px;
        border: $card-border;
        box-shadow: $box-shadow;
        border-radius: 100%;
    }
    &.btn-light-white {
        background: $white-light;
        color: $title-color;
        box-shadow: $btn-shadow;
        border: $card-border;
        font-weight: 700;
        &:focus {
            outline: none;
        }
        svg {
            stroke: $title-color;
        }
    }
    &.btn-outline {
        border: $card-border;
        color: $title-color;
        padding: 11px 40px;
        background: $white;
        font-weight: 700;
        box-shadow: $box-shadow;
        &:focus {
            outline: none;
        }
    }

    &.btn-lg {
        padding: 11px 40px;
    }
    &.btn-sm {
        padding: 5px 8px;
        font-size: 14px;
    }
    &.btn-md {
        padding: 8px 20px;
        font-size: 14px;
    }
    &.btn-theme {
        border: $card-border;
        box-shadow: $box-shadow;
    }
}
