/*=====================
  4.9 Startup Page CSS
=======================*/

/// Startup page 1 Start ///

.header-section {
  position: relative;
  text-align: center;
  .banner-box {
    overflow: hidden;
  }
  .dote {
    position: absolute;
    top: 2%;
    left: 2%;
    animation: moveright 2s linear infinite both;
    img {
      height: auto;
    }
  }
  .banner-img {
    margin-top: -55%;
    text-align: center;
    img {
      width: 80%;
    }
  }
}
.main-section {
  position: relative;
  text-align: center;
  margin-top: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)));
  .content-box {
    padding: 0 calc(35px + (110 - 35) * ((100vw - 320px) / (1920 - 320)));
  }
  .btn-box {
    padding: 0 calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    margin-top: calc(45px + (60 - 45) * ((100vw - 320px) / (1920 - 320)));

    .btn {
      display: block;
    }
    & > a {
      &:last-child {
        font-weight: 700;
        display: block;
      }
    }
  }
  .dote-bottom {
    bottom: 3%;
  }
}

/// Landing Page Css Start ///
.landing-page {
  .title-2 {
    font-weight: 600;
    margin-bottom: 15px;
  }
  .landing-page-slider {
    [dir="rtl"] & {
      direction: ltr;
    }
    .banner-box {
      text-align: center;
      .img-box {
        padding: 8px;
        border: $card-border;
        box-shadow: $box-shadow;
        display: block;
        border-radius: 15px;
        img {
          border-radius: 15px;
        }
      }
      h2 {
        margin-top: 10px;
      }
    }

    .slick-list {
      padding: 4px 0;
      padding-left: 0 !important;
      margin-left: -15px;
      margin-right: -15px;
      .slick-slide {
        padding: 0 10px;
      }
    }
  }
  .article-section {
    .reading-box {
      border: $card-border;
      box-shadow: $box-shadow;
      display: block;
      border-radius: 8px;
      padding: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));
      h3 {
        margin-bottom: 8px;
        font-weight: 600;
      }
      p {
        margin-bottom: -3px;
      }
    }
  }
  .language-section {
    @include categores-slider;
  }
  .page-list-section {
    .media {
      border: $card-border;
      box-shadow: $box-shadow;
      border-radius: 8px;
      padding: 8px;
      &:not(:first-of-type) {
        margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
      }
      img {
        width: calc(70px + (100 - 70) * ((100vw - 320px) / (1920 - 320)));
        height: auto;
        border-radius: 6px;
      }
      .media-body {
        h5 {
          font-weight: 600;
          margin-bottom: 5px;
        }
        span {
          display: flex;
          align-items: center;
          gap: 5px;
          svg {
            width: 20px;
            height: 20px;
            stroke-width: 1.5px;
          }
        }
      }
    }
  }
}
/// Landing Page Css End  ///
