/*=====================
 2.16 Progress CSS
==========================*/

$circle-size: 60px;
$circle-background: $gray-light;
$circle-color: $theme-danger;
$inset-size: 50px;
$inset-color: $white;
$transition-length: 1s;

.ko-progress-circle {
    width: $circle-size;
    height: $circle-size;
    background-color: $circle-background;
    border-radius: 50%;
    .ko-progress-circle__slice,
    .ko-progress-circle__fill {
        width: $circle-size;
        height: $circle-size;
        position: absolute;
        backface-visibility: hidden;
        transition: transform $transition-length;
        border-radius: 50%;
    }
    .ko-progress-circle__slice {
        clip: rect(0px, $circle-size, $circle-size, $circle-size/2);
        .ko-progress-circle__fill {
            clip: rect(0px, $circle-size/2, $circle-size, 0px);
            background-color: $circle-color;
        }
    }
    .ko-progress-circle__overlay {
        width: $inset-size;
        height: $inset-size;
        position: absolute;
        margin-left: ($circle-size - $inset-size)/2;
        margin-top: ($circle-size - $inset-size)/2;
        background-color: $inset-color;
        border-radius: 50%;
    }

    $i: 0;
    $increment: 180deg / 100;
    @while $i <= 100 {
        &[data-progress="#{$i}"] {
            .ko-progress-circle__slice.full,
            .ko-progress-circle__fill {
                transform: rotate($increment * $i);
            }
            .ko-progress-circle__fill.ko-progress-circle__bar {
                transform: rotate($increment * $i * 2);
            }
            $i: $i + 1;
        }
    }
}
