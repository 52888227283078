/*=======================
  4.7 Page Catagories CSS
=========================*/

.page-catagories {
    .catagories {
        .catagories-item {
            text-align: center;
            position: relative;
            border: $card-border;
            box-shadow: $box-shadow;
            border-radius: 15px;
            .bg-wrap {
                opacity: 0.7;
            }
            .content {
                width: 100%;
                padding: 7px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                a {
                    img {
                        width: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
                        height: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
                    }
                    h2 {
                        line-height: 22px;
                        font-weight: 600;
                        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
                        margin-top: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
                        margin-bottom: calc(0px + (6 - 0) * ((100vw - 320px) / (1920 - 320)));
                    }
                    h3 {
                        line-height: 16px;
                    }
                }
            }
            .shape2-right {
                right: 9.5%;
            }
        }
    }
    .row {
        [class^="col"] {
            &:nth-last-of-type(-n + 2) {
                .shape2 {
                    display: none;
                }
            }
            @media only screen and (max-width: 475px) {
                .shape2 {
                    bottom: -60px;

                    .line {
                        height: 40px;
                    }
                }
            }
            &:nth-child(5n + 1) {
                .catagories-item {
                    background-color: $theme-blue;
                }
            }
            &:nth-child(5n + 2) {
                .catagories-item {
                    background-color: $theme-yellow;
                }
            }
            &:nth-child(5n + 3) {
                .catagories-item {
                    background-color: $theme-green;
                }
            }
            &:nth-child(5n + 4) {
                .catagories-item {
                    background-color: $theme-purple;
                }
            }
            &:nth-child(5n + 5) {
                .catagories-item {
                    background-color: $theme-orange;
                }
            }
        }
    }
    @media only screen and (max-width: 576px) {
        .ratio-83 {
            .bg-size {
                &:before {
                    padding-top: 90%;
                }
            }
        }
    }
    @media only screen and (max-width: 375px) {
        .row {
            &.g-3,
            &.gy-3 {
                --bs-gutter-y: 13px !important;
            }
            &.g-3,
            &.gx-3 {
                --bs-gutter-x: 13px;
            }
            [class^="col"] {
                width: 100%;
                &:not(:last-of-type) {
                    .shape2 {
                        display: flex;
                    }
                }
                .shape2 {
                    bottom: -45px;
                    .line {
                        height: 33px;
                        width: 4px;
                    }
                    .circle {
                        z-index: 1;
                        width: 10px;
                        height: 10px;
                    }
                }
            }
            .ratio-83 {
                .bg-size {
                    &:before {
                        padding-top: 70%;
                    }
                }
            }
            .shape2 {
                bottom: -45px;
                .line {
                    height: 33px;
                    width: 4px;
                }
                .circle {
                    z-index: 1;
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
}
