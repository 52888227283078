@import url("https://fonts.googleapis.com/css?family=Poppins:900&display=swap");
.pre-loader {
    margin: 0;
    background-color: $white;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
    color: $title-color;
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.loader-wrap {
    transform: scale(0.3);
    @media only screen and (max-width: 475px) {
        transform: scale(0.25);
    }
    .container {
        filter: url("#gooey");
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .liquid-1 {
        position: relative;
    }

    span {
        letter-spacing: 10px;
        font-size: 8rem;
    }

    .drop {
        position: absolute;
        background: $title-color;
        width: 22px;
        height: 22px;
        border-radius: 60% 70% 50% 60% / 65% 66% 60% 65%;
        animation: 6s move ease infinite;
    }

    .drop:after {
        width: 17px;
        height: 17px;
        content: "";
        position: absolute;
        background: $title-color;
        border-radius: 50% 60% 60% 70% / 60% 65% 65% 65%;
        left: 25px;
        top: 3px;
        animation: 6s drop_effect ease infinite;
    }

    svg {
        position: absolute;
    }
}
@keyframes move {
    0%,
    100% {
        transform: translate(370px);
    }

    50% {
        transform: translate(-370px);
    }
}

@keyframes drop_effect {
    0% {
        left: 0px;
    }
    5% {
        left: 35px;
    }
    45% {
        left: 0px;
    }
    50% {
        left: 0px;
    }
    55% {
        left: -30px;
    }
    95% {
        left: 0px;
    }
    100% {
        left: 0;
    }
}
