/*=====================
  2.6 CountDown CSS
==========================*/

.countdown,
.otp-input {
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            width: calc(70px + (120 - 70) * ((100vw - 320px) / (1920 - 320)));
            z-index: 2;
            font-size: calc(10px + (16 - 16) * ((100vw - 320px) / (1920 - 320)));
            position: relative;
            font-weight: 400;
            font-size: 12px;
            &:not(:last-of-type) {
                margin-right: 10px;
                [dir="rtl"] & {
                    margin-left: 10px;
                    margin-right: unset;
                }
                @media only screen and (max-width: 424px) {
                    margin-right: 20px;
                    [dir="rtl"] & {
                        margin-left: 20px;
                        margin-right: unset;
                    }
                }
            }
            span {
                font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 600;
                margin-bottom: -3px;

                display: flex;
                flex-direction: column;
            }
            .bg-shape {
                fill: $theme-yellow;
                z-index: -1;
                width: 112%;
                height: 150%;
                position: absolute;
                top: -22%;
                left: -7%;
                transform: translate(-50%, -50%);
            }
            &:nth-child(2n + 1) {
                .bg-shape {
                    transform-origin: center;
                    transform: rotate(11deg);
                }
            }
            &:nth-child(2n + 2) {
                .bg-shape {
                    transform-origin: center;
                    transform: rotate(22deg);
                }
            }
        }
    }
}
