/*=====================
 2.15 Pricing Card CSS
==========================*/

.pr-card {
    padding: 30px 10px;
    border: $card-border;
    box-shadow: $box-shadow;
    border-radius: 15px;
    text-align: center;
    .pr-header {
        margin-bottom: 5px;
        .heading {
            font-weight: 600;
            font-size: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
            margin-bottom: 4px;
        }
        span {
            font-size: 14px;
            color: $content-color;
        }
    }
    .pr-body {
        margin-bottom: 25px;
        p {
            color: $content-color;
            margin-top: 20px;
            font-size: 14px;
        }
        span {
            font-size: 35px;
            color: $title-color;
            position: relative;
            font-weight: 600;
            span {
                font-size: 14px;
                color: $content-color;
                position: absolute;
                bottom: -16px;
                right: 3px;
                font-weight: 400;
            }
        }
    }
    .pr-footer {
        .btn {
            padding: 10px 20px;
            font-size: 14px;
        }
    }
    &.pr-card-2 {
        padding: 40px 10px 40px 10px;
        overflow: hidden;
        .heading {
            position: relative;
            &::before {
                content: "";
                width: 50px;
                height: 4px;
                background-image: $dark-gradient;
                position: absolute;
                top: -14px;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 10px;
                opacity: 0.7;
            }
        }
        .pr-body {
            > span {
                border: $card-border;
                box-shadow: $box-shadow;
                width: 120px;
                position: relative;
                margin: 20px auto;
                height: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-radius: 100%;
                font-size: 32px;
                span {
                    position: relative;
                    top: 0;
                    right: 0;
                }
            }
        }
        .pr-footer {
            position: relative;
            .circle {
                width: 221px;
                height: 221px;
                border-radius: 100%;
                position: absolute;
                left: -133px;
                z-index: -1;
                bottom: -196px;
                border: $card-border;
            }
        }
    }
    &.pr-card-3 {
        .pr-body {
            > span {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 55px;
                span {
                    position: relative;
                    bottom: 0;
                    font-size: 30px;
                    right: 0;
                    color: $title-color;
                    margin-right: 8px;
                }
            }
            .heading {
                font-weight: 600;
                font-size: 14px;
            }
            p {
                margin-top: 5px;
            }
        }
    }
    &.pr-card-4 {
        padding: 10px;
        position: relative;
        overflow: hidden;
        .bg-shape {
            position: absolute;
            z-index: -1;
            transform-origin: center;
            transform: rotate(194deg);
            width: 378px;
            height: 395px;
            top: -160px;
            left: -62px;
            @media only screen and (max-width: 400px) {
                left: 50%;
                transform: translateX(-50%);
                width: 435px;
                height: 436px;
                top: -236px;
            }
        }
        .pr-header {
            margin-bottom: 0;
            padding: 20px 20px 10px 20px;
            > div {
                padding-bottom: 10px;
                border-bottom: 2px solid $border-color;
            }
        }
        .pr-body {
            margin: 0;
            margin-top: 10px;
            p {
                margin-bottom: 0;
                margin-top: 28px;
            }
        }
        .pr-footer {
            margin: 20px 0;
        }
    }
}
