/// Font color ///
$title-color: #222222;
$content-color: #777777;
$active-color: #bd937e;

/// Main Color ///
$black: #000000;
$white: #ffffff;
$white-light: #f0f0f0;
$gray-light: #f8f8f8;
$theme-purple: #e5e7ff;
$theme-light-purple: #fbf5ff;
$theme-orange: #ffdfde;
$theme-dark-orange: #ff7c48;
$theme-green: #deffe2;
$theme-yellow: #fff5d9;
$theme-yellow-dark: #ffbf13;
$theme-blue: #d6fffa;
$theme-danger: #e93939;
$theme-success: #34a853;

/// social color ///
$facebook: #385cb4;
$twitter: #00a0dd;
$whatsapp: #1abc56;
$instagram: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
$youtube: #fe4653;
$linkdin: #0077b5;
$google: #db3236;

/// Font Familly ///
$font-1: "Nunito Sans", sans-serif;

/// Gradient color ///
$dark-from: #2d2d2d;
$dark-to: #3e3e3e;

/// Gradient color ///
$dark-gradient: linear-gradient(269.96deg, $dark-from 0.03%, $dark-to 99.96%);

/// Border color ///
$border-color: #222222;

/// border ///
$card-border: 1px solid $border-color;

/// Btn shadow ///
$btn-shadow: 0px 4px 4px rgba($black, 0.25);

/// main box shadow ///
$box-shadow: 1px 1px 0 $border-color;

/// Dark Theme color ///
$dark-content-color: #747474;
$dark-bg-color: #22282c;
$dark-grey-color: #2e343b;
