/*=====================
  2.20 Table CSS
==========================*/

.theme-bordered > :not(caption) > * > * {
    border: $card-border !important;
}
thead,
tbody,
tfoot,
tr,
td,
th {
    color: $title-color;
}
th {
    border-bottom: 1px solid rgba($border-color, 0.1) !important;
}
.table-dark {
    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
        color: $white;
    }
}
.round-box {
    width: 100%;
    border-collapse: inherit;
    table-layout: fixed;
    text-align: center;
    box-shadow: $box-shadow;
    border-radius: 15px;
    border-spacing: 0px;
    border: $card-border;

    tbody {
        tr {
            &:first-child {
                color: $title-color;
                td {
                    &:first-child {
                        border-radius: 15px 0 0 0;
                        [dir="rtl"] & {
                            border-radius: 0px 15px 0 0;
                        }
                    }
                    &:last-child {
                        border-radius: 0 15px 0 0;
                        [dir="rtl"] & {
                            border-radius: 15px 0 0 0;
                        }
                    }
                }
            }
            &:last-child {
                td {
                    border: none;
                }
            }
        }
    }
}
