/**=====================
  2.19 Switch css start
==========================**/

.toggle-sec {
  display: flex;
  align-items: center;
  label {
    margin-right: 20px;
    [dir="rtl"] & {
      margin-right: 0;
      margin-left: 20px;
    }
    span {
      display: block;
      color: $content-color;
    }
  }
  .toggle-btn {
    position: relative;
    width: 70px;
    height: 33px;
    overflow: hidden;
    border-radius: 100px;
    margin-left: auto;
    [dir="rtl"] & {
      margin-right: auto;
      margin-left: 0;
    }
    .knobs,
    .layer {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: $white-light;
      transition: 0.3s ease all;
      z-index: 1;
      left: 0;
    }
    .checkbox {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      opacity: 0;
      cursor: pointer;
      z-index: 3;
    }
    .knobs {
      z-index: 2;
      &:before,
      &:after,
      span {
        position: absolute;
        top: 3px;
        width: 27px;
        height: 27px;
        font-size: 10px;
        font-weight: bold;
        text-align: center;
        line-height: 1;
        padding: 9px 4px;
        border-radius: 50%;
        transition: 0.4s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
      }
      &:before {
        content: "No";
        left: 4px;
        color: $white-light;
      }
      &:after {
        content: "Yes";
        right: -24px;
        color: $white-light;
      }
      &:before,
      &:after {
        z-index: 2;
        [class="dark"] & {
          color: $white;
        }
      }
      span {
        left: 4px;
        background-color: rgba($black, 0.6);
        z-index: 1;
      }
    }
    .checkbox {
      &:checked {
        + .knobs {
          &:before {
            left: -24px;
          }
          &:after {
            right: 1px;
          }
          span {
            left: 42px;
            background-color: rgba($black, 0.6);
          }
          ~ .layer {
            background-color: $white-light;
          }
        }
      }
    }
  }
}
