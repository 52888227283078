/*=====================
  2.10 Modal  CSS
==========================*/

/// Chat Modal Start ///
.chat-modal {
    max-width: 600px;
    left: 50%;
    transform: translateX(-50%);
    [dir="rtl"] & {
        right: unset;
    }
    .modal-fullscreen {
        width: 100%;
        .modal-header {
            border: none;
        }
        .modal-body {
            padding: 20px 0;
            text-align: center;
            width: 100%;
            height: auto;
            .content-box {
                position: relative;
                text-align: center;
                transform: translateY(-50%);
                top: 50%;
                img {
                    width: 50%;
                    border-radius: 100%;
                    border: $card-border;
                    box-shadow: $box-shadow;
                }
                > svg {
                    width: 30px;
                    height: 30px;
                    margin-top: 20px;
                }
                h3 {
                    font-weight: 600;
                    margin-top: 23px;
                    font-size: 25px;
                }
                .call-option {
                    margin-top: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    button {
                        @include bg-color();
                        padding: 8px;
                        border-radius: 100%;
                        &:nth-of-type(3) {
                            background-color: $theme-orange;
                            svg {
                                width: 35px;
                                height: 35px;
                            }
                        }
                        &:nth-of-type(5) {
                            background-color: $theme-green;
                        }
                        &:not(:last-of-type) {
                            margin-right: 15px;
                            [dir="rtl"] & {
                                margin-left: 15px;
                                margin-right: unset;
                            }
                        }
                    }
                }
            }

            &.video-call {
                position: relative;
                padding: 0;
                .timer {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
                img {
                    border-radius: 0;
                    width: 100%;
                    height: 100%;
                }
                .content-box {
                    position: absolute;
                    left: 50%;
                    top: unset;
                    bottom: 80px;
                    transform: translateX(-50%);
                }
                .call-option {
                    align-items: flex-end;
                    .video-screen {
                        width: 100px;
                        height: 150px;
                        margin-right: 20px;
                        border: $card-border;
                        box-shadow: $box-shadow;
                        border-radius: 5px;
                        [dir="rtl"] & {
                            margin-right: unset;
                            margin-left: 20px;
                        }
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    button {
                        margin-bottom: 25px;
                        @include bg-color();
                        background-color: $white;
                        &:nth-of-type(2) {
                            background-color: $theme-orange;
                            svg {
                                width: 24px;
                                height: 24px;
                            }
                        }
                        &:nth-of-type(3) {
                            background-color: $theme-green;
                            svg {
                                width: 24px;
                                height: 24px;
                            }
                        }
                        &:not(:last-of-type) {
                            margin-right: 15px;
                        }
                    }
                }
            }
        }
    }
}
/// Chat Modal End ///

/// Filter Modal Start ///
.filter-modal {
    width: 100%;
    .modal-dialog {
        max-width: 600px;
        margin: 0 auto;
        height: 100%;
    }
    .modal-content {
        border-radius: 0;
    }
    .close-modal {
        img {
            [class="dark"] & {
                filter: invert(1);
            }
        }
    }
    .modal-header {
        padding: 13px 15px;
        > span {
            background-image: $dark-gradient;
            border-radius: 100%;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: 18px;
                height: 18px;
                stroke: $white;
            }
        }
    }
    .modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 13px 15px;
        background-color: $white;
        a {
            width: 46%;
            text-align: center;
        }
        .reset-link {
            color: $title-color !important;
        }
        .btn {
            font-size: 14px;
            color: $white;
            padding: 12px;
        }
    }
    .modal-body {
        padding: 24px 15px;
        .filter-box {
            margin-bottom: 25px;
            .m-b-title1 {
                margin-bottom: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
            }
            select {
                padding: 7px 15px;
                font-size: 15px;
            }
        }
        .pricing-slider {
            .irs-min,
            .irs-max {
                display: none;
            }
            .irs-from,
            .irs-to,
            .irs-single {
                background-color: rgba($theme-orange, 0.2);
                color: $title-color;
                font-size: 12px;
            }
            .irs-line {
                height: 4px;
                border: none;
                background-color: $gray-light;
                background: $gray-light;
                border-radius: 2px;
            }
            .irs-bar {
                height: 4px;
                border: none;
                background-color: $theme-orange;
                background: $theme-orange;
                border-radius: 2px;
            }
            .irs-slider {
                height: 22px;
                width: 22px;
                background: $white;
                border: 1px solid $theme-orange;
                &:after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    background-color: $theme-orange;
                    width: 12.5px;
                    height: 12.5px;
                    border-radius: 100%;
                }
            }
        }
    }
    .filter-color {
        margin: -6px;
        display: flex;
        align-items: center;
        li {
            margin: 4px 6px;
            .color-box {
                width: 28px;
                height: 28px;
                border-radius: 100%;
                position: relative;
                &:after {
                    content: "";
                    background-image: url("../images/e-commerce/icon/tick.svg");
                    top: 8px;
                    right: 5px;
                    height: 17px;
                    width: 17px;
                    background-repeat: no-repeat;
                    position: absolute;
                    transform: scale(0);
                    transition: all 0.3s ease;
                }
            }
            &.active {
                .color-box {
                    &:after {
                        transform: scale(0.8);
                        transition: all 0.3s ease;
                    }
                }
            }
        }
    }
    .filter-row {
        li {
            .filter-col {
                border: 1px solid transparent;
                border: $card-border;
                box-shadow: $box-shadow;
                color: $title-color;
                font-size: 14px;
                padding: 7px;
                text-align: center;
                border-radius: 5px;
                transition: all 0.5s ease;
                text-transform: capitalize;
            }
            &.active {
                .filter-col {
                    background-color: rgba($theme-orange, 0.5);
                    transition: all 0.5s ease;
                }
            }
        }
    }
}
/// Filter Modal End ///

/// Off Canvass Css Start ///
.offcanvas {
    max-width: 600px;
    margin: 0 auto;
    border-radius: 5px;
    &.offcanvas-bottom {
        height: auto;
    }
    .offcanvas-header {
        background-color: $white;
        padding: 15px 15px;
        border-bottom: 1px solid rgba($border-color, 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        > span {
            background-image: $dark-gradient;
            border-radius: 100%;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: 18px;
                height: 18px;
                stroke: $white;
            }
        }
        h5 {
            font-size: 20px;
        }
    }
    .offcanvas-body {
        background-color: $white;
        padding: 32px 15px;
        ul {
            li {
                .filter-col {
                    border: 1px solid rgba($border-color, 0.1);
                    color: $title-color;
                    font-size: 14px;
                    padding: 10px 15px 10px 15px;
                    text-align: center;
                    border-radius: 5px;
                    transition: all 0.5s ease;
                    text-transform: capitalize;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    background-color: $white;
                    position: relative;
                    &::after {
                        content: "";
                        background-image: url(../images/e-commerce/icon/tick.svg);
                        width: 18px;
                        height: 18px;
                        right: 10px;
                        top: 60%;
                        transition: all 0.3s;
                        opacity: 0;
                        transform: translateY(-50%);
                        position: absolute;
                        background-repeat: no-repeat;
                        [dir="rtl"] & {
                            right: unset;
                            left: 10px;
                        }
                    }
                    span {
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        margin-right: 10px;
                        [dir="rtl"] & {
                            margin-right: unset;
                            margin-left: 10px;
                        }
                    }
                }
                &.active {
                    .filter-col {
                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .offcanvas-footer {
        border-top: 1px solid rgba($border-color, 0.1);
        display: flex;
        align-items: center;
        padding: 15px 15px;
        background-color: $white;
        .btn {
            font-size: 14px;
            box-shadow: none;
            width: 100%;
            padding: 12px;
            &:first-of-type {
                margin-right: 16px;
                font-weight: 400;
                background: $white;
                color: $title-color;
                [dir="rtl"] & {
                    margin-left: 16px;
                    margin-right: unset;
                }
            }
        }
    }
    &.history {
        height: 80vh;
        .offcanvas-body {
            padding: 15px;
            > div {
                h3 {
                    font-size: 14px;
                    margin-bottom: 15px;
                    margin-top: 15px;
                    font-weight: 400;
                }
                &:not(:first-of-type) {
                    padding: 15px 0;
                }
            }
            .filter-col {
                color: $content-color;
            }
        }
    }
}
/// Off Canvass Css End ///

/// add Home Pwa Offcanvas ///
.addtohome-popup {
    height: 150px;

    .install-app {
        margin-top: 20px;
        padding: 8px 20px;
        font-size: 14px;
        float: right;
        [dir="rtl"] & {
            float: left;
        }
    }
    .app-info {
        display: flex;
        align-items: center;
        .content {
            padding-left: 15px;
            width: 100%;
            [dir="rtl"] & {
                padding-left: 0;
                padding-right: 15px;
            }
            h3 {
                font-weight: 600;
                color: $title-color;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .btn-close {
                    width: 24px;
                    height: 24px;
                    margin-left: auto;
                    [class="dark"] & {
                        filter: invert(1);
                    }
                }
            }
            a {
                color: $content-color;
            }
        }
    }
}
