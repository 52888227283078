/*=====================
  4.5 General pages CSS
==========================*/

/// Setting Page Css Start ///
.setting {
  ul {
    li {
      padding: 13px;
      border: $card-border;
      box-shadow: $box-shadow;
      border-radius: 8px;
      &:not(:first-child) {
        margin-top: 20px;
      }
      a {
        position: relative;
        & > div {
          color: $title-color;
          font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 400;
        }
        img {
          z-index: 2;
          width: 20px;
          height: 20px;
          margin-left: 12px;
          [dir="rtl"] & {
            margin-left: unset;
            margin-right: 12px;
          }
        }
        & > svg {
          &:first-child {
            z-index: 2;
            stroke-width: 1px;
            stroke: $title-color;
            width: 20px;
            height: 20px;
            margin-left: 12px;
            [dir="rtl"] & {
              margin-left: unset;
              margin-right: 12px;
            }
          }
          &:last-child {
            margin-right: -5px;
            margin-left: auto;
            stroke: $title-color;
            width: 20px;
            height: 20px;
            [dir="rtl"] & {
              margin-right: auto;
              margin-left: unset;
              transform-origin: center;
              transform: rotate(180deg);
            }
          }
        }
        .media-body {
          z-index: 2;
          margin-left: 25px;
          [dir="rtl"] & {
            margin-right: 25px;
            margin-left: unset;
          }
        }
        .bg-shape {
          position: absolute;
          width: 42px;
          left: 0;
          height: 50px;
          z-index: 1;
          [dir="rtl"] & {
            left: unset;
            right: 0;
          }
        }
      }
      @include bg-shape-fill();
      &:nth-of-type(4n + 1) {
        .bg-shape {
          transform: rotateZ(-7deg);
          transform-origin: center;
        }
      }
      &:nth-of-type(4n + 2) {
        .bg-shape {
          top: -13px !important;
          transform: rotateZ(20deg);
          transform-origin: center;
        }
      }
      &:nth-of-type(4n + 3) {
        .bg-shape {
          top: -13px !important;
          transform: rotateZ(14deg);
          transform-origin: center;
        }
      }
      &:nth-of-type(4n + 4) {
        .bg-shape {
          transform: rotateZ(7deg);
          transform-origin: center;
        }
      }
    }
  }
}
/// Setting Page Css End ///

/// Search Page Css Start ///
.Search {
  .search-box {
    @include search-box();
  }
  .categories {
    @include categores-slider();
  }
  .recent-search {
    h2 {
      margin-top: -3px;
    }
    ul {
      li {
        &:not(:first-child) {
          margin-top: 20px;
        }
        @include bg-color();
        border-radius: 8px;
        border: $card-border;
        box-shadow: $box-shadow;
        padding: 15px 18px;
        a {
          display: flex;
          text-transform: capitalize;
          align-items: center;
          font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
          svg {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            [dir="rtl"] & {
              margin-left: 10px;
              margin-right: unset;
            }
          }
        }
      }
    }
  }
}
/// Search Page Css End ///

/// About Us Page Css Start ///
.about-us {
  .banner-box {
    @include banner();
    padding: 10px 42px 0 42px;
    background-color: $theme-yellow;
  }
  .intro {
    @include dashed-border-bottom();

    h2 {
      margin-bottom: calc(7px + (8 - 7) * ((100vw - 320px) / (1920 - 320)));
    }
    p {
      text-align: justify;
      margin-bottom: calc(7px + (8 - 7) * ((100vw - 320px) / (1920 - 320)));
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .card-box {
    margin-top: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 4px;
    ul {
      li {
        position: relative;
        &:not(:first-child) {
          margin-top: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
        }
        @include bg-color();
        border: $card-border;
        box-shadow: $box-shadow;
        padding: 13px 20px;
        border-radius: 5px;
        span {
          display: flex;
          align-items: center;
          svg {
            width: 22px;
            height: 22px;
            margin-right: 15px;
            [dir="rtl"] & {
              margin-left: 15px;
              margin-right: unset;
            }
          }
        }
        &:last-child {
          .shape2 {
            display: none;
          }
        }
      }
    }
    .shape2-left {
      left: 13px;
    }
    .shape2-right {
      right: 3px;
    }
    .shape2 {
      .circle {
        width: 10px;
        height: 10px;
      }
      bottom: -49px;
      .line {
        width: 4px;
        height: 33px;
      }
      @media only screen and (max-width: 375px) {
        .card-box {
          .shape2 {
            bottom: -48px;
          }
        }
      }
    }
  }
  .mission {
    @include dashed-border-bottom();
    h2 {
      margin-bottom: calc(7px + (8 - 7) * ((100vw - 320px) / (1920 - 320)));
    }
    .masonery-box {
      margin-top: calc(19px + (25 - 19) * ((100vw - 320px) / (1920 - 320)));
      img {
        max-width: 100%;
        height: auto;
        vertical-align: middle;
        display: inline-block;
        border-radius: 5px;
      }

      /* Main CSS */
      .grid-wrapper {
        & > div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .grid-wrapper {
        & > div {
          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
          }
        }
      }

      .grid-wrapper {
        display: grid;
        grid-gap: calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320)));
        grid-template-columns: repeat(6, minmax(auto, 1fr));
        grid-template-rows: repeat(3, minmax(auto, 1fr));
      }
      .grid-wrapper {
        .wide {
          grid-column: span 4;
          grid-row: span 2;
        }
      }
      .grid-wrapper {
        .small {
          grid-column: span 2;
        }
      }
      .grid-wrapper {
        .big {
          grid-column: span 2;
          grid-row: span 1;
        }
      }
      .grid-wrapper {
        .half {
          grid-column: span 3;
          grid-row: span 1;
        }
      }
    }
  }
  .people-say {
    @include dashed-border-bottom();
    h2 {
      margin-bottom: calc(7px + (8 - 7) * ((100vw - 320px) / (1920 - 320)));
    }
    .slick-container {
      width: 900px;
      margin: 0 auto;
      max-width: 80%;
    }
    .slick-arrow {
      display: none;
    }

    .slick-dots {
      text-align: center;
      position: relative;
      bottom: 0px;
      left: 0;
      right: 0;
      margin-top: -12px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 456px) {
        margin-top: 15px;
      }
      li {
        position: relative;
        display: inline-block;
        height: 10px;
        width: 10px;
        margin: 0 5px;
        border-radius: 100%;
        background-color: $white;
        border: $card-border;
        padding: 0;
        cursor: pointer;
      }
      .slick-active {
        background-color: $title-color !important;
        height: 10px;
        width: 30px;
        border-radius: 20px;
      }
    }
    .slick-slider {
      margin-left: -12%;
      margin-right: -12%;
    }
    .slick-list {
      padding-top: 10% !important;
      padding-bottom: 11% !important;
      padding-left: 15% !important;
      padding-right: 15% !important;
      @media only screen and (max-width: 384px) {
        padding-top: 15% !important;
        padding-bottom: 11% !important;
        padding-left: 15% !important;
        padding-right: 15% !important;
      }
    }
    .slick-track {
      max-width: 100% !important;
      transform: translate3d(0, 0, 0) !important;
      perspective: 100px;
    }
    .slick-dotted {
      &.slick-slider {
        margin-bottom: 0;
      }
    }
    .slick-slide {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      opacity: 0;
      width: 100% !important;
      transform: translate3d(0, 0, 0);
      transition: transform 1s, opacity 1s;
    }

    .slick-snext,
    .slick-sprev {
      display: block;
    }

    .slick-current {
      opacity: 1;
      position: relative;
      display: block;
      transform: translate3d(0, 0, 20px);
      z-index: 2;
    }

    .slick-snext {
      opacity: 1;
      transform: translate3d(00, 0, 0px);
      z-index: 1;
    }

    .slick-sprev {
      opacity: 1;
      transform: translate3d(-16%, 0, 0px);
    }
    .people-box {
      display: block;
      padding: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320))) calc(15px + (50 - 15) * ((100vw - 320px) / (1920 - 320)));
      width: 100%;
      height: auto;
      background: $theme-yellow;
      border: $card-border;
      border-radius: 10px;
      box-shadow: $box-shadow;
      text-align: center;
      p {
        margin-bottom: 15px;
      }
      .media {
        justify-content: center;
        img {
          width: 38px;
          height: 38px;
          border-radius: 100%;
          border: $card-border;
        }
        .media-body {
          text-align: left;
          margin-left: 11px;
          [dir="rtl"] & {
            text-align: right;
          }
          h3 {
            font-size: 14px;
            font-weight: 400;
            line-height: 13px;
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
  .about-team {
    > h2 {
      margin-bottom: calc(7px + (8 - 7) * ((100vw - 320px) / (1920 - 320)));
    }
    .content-box {
      h3 {
        margin-bottom: -2px;
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(19px + (26 - 19) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }
}
/// About Us Page Css End ///

/// Contact Us Page Css Start ///
.contact-us {
  .banner-box {
    @include banner();
    padding: 20px;
    background-color: $theme-orange;
  }
  .location {
    @include dashed-border-bottom();
    & > div {
      &:not(:first-child) {
        margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
      }
      .media {
        & > span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border: $card-border;
          border-radius: 50%;
          box-shadow: $box-shadow;
          svg {
            width: 18px;
            height: 18px;
          }
        }
        .media-body {
          margin-left: 14px;
          span {
            font-size: calc(16px + (19 - 16) * ((100vw - 320px) / (1920 - 320)));
          }
        }
      }
      p {
        margin-top: 13px;
        margin-bottom: -3px;
        font-size: calc(17px + (17 - 17) * ((100vw - 320px) / (1920 - 320)));
      }
      &:nth-of-type(5n + 1) {
        .media {
          & > span {
            background-color: $theme-blue;
          }
        }
      }

      &:nth-of-type(5n + 2) {
        .media {
          & > span {
            background-color: $theme-yellow;
          }
        }
      }

      &:nth-of-type(5n + 3) {
        .media {
          & > span {
            background-color: $theme-green;
          }
        }
      }

      &:nth-of-type(5n + 4) {
        .media {
          & > span {
            background-color: $theme-purple;
          }
        }
      }

      &:nth-of-type(5n + 5) {
        .media {
          & > span {
            background-color: $theme-orange;
          }
        }
      }
    }
  }
  .form-section {
    h2 {
      margin-top: -3px;
      margin-bottom: calc(7px + (8 - 7) * ((100vw - 320px) / (1920 - 320)));
    }
    .custom-form {
      .tex-box {
        svg {
          left: 12px;
          top: 19%;
          transform: translateY(-50%);
        }
      }
      button {
        box-shadow: none;
        font-size: 16px;
      }
    }
  }
}
/// Contact Us Page Css End ///

/// Message Page Css Start ///
.message-page {
  .search-box {
    display: flex;
    align-items: stretch;
    input[type="search" i]:enabled:read-write:-webkit-any(:focus, :hover)::-webkit-search-cancel-button {
      display: none;
    }
    input {
      padding: 10px 47px;
    }
    position: relative;
    > svg {
      position: absolute;
      width: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
      height: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
      top: 49%;
      cursor: pointer;
      transform: translateY(-50%);
      left: 16px;
      [dir="rtl"] & {
        left: unset;
        right: 16px;
      }
    }
    span {
      display: flex;
      align-items: center;
      justify-self: center;
      margin-left: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
      padding: 10px 14px;
      border: $card-border;
      box-shadow: $box-shadow;
      border-radius: 8px;
      [dir="rtl"] & {
        margin-left: unset;
        margin-right: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
      }
      svg {
        width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
        height: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }
  .status-box {
    margin-bottom: -6px;
    .Status-Slider {
      text-align: center;
      display: flex;
      align-items: center;
      overflow-x: hidden;
      gap: 10px;
      &:hover {
        overflow-x: auto;
      }
      @media only screen and (max-width: 767px) {
        overflow-x: auto;
      }

      & > div {
        img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          object-fit: cover;
          border: $card-border;
          margin: 0 auto;
          margin-bottom: 8px;
        }
        span {
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        }
      }
      .user {
        > span {
          width: max-content;
          display: block;
          text-align: center;
        }
        & > div {
          position: relative;
          text-align: center;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            right: 11px;
            bottom: 2px;
            position: absolute;
            width: 26px;
            height: 26px;
            background-color: $theme-purple;
            border: 3px solid $white-light;
            border-radius: 50%;
            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }
  .recent-chat {
    h2 {
      margin-top: -3px;
    }
    .friend-box {
      .media {
        margin-top: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
        position: relative;
        img {
          width: 57px;
          height: 57px;
          border-radius: 100%;
          object-fit: cover;
        }
        .dashed-border {
          border-radius: 100%;
          padding: 4px;
          background-position: center;
          background-image: radial-gradient($white 65%, transparent 68% 50%, $white 70%), repeating-conic-gradient($border-color 0% 3%, transparent 3% 4%);
        }
        .media-body {
          margin-left: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
          h3 {
            font-weight: 600;
            display: flex;
            align-items: center;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            span {
              margin-left: 10px;
              padding: 4px 8px;
              font-weight: 400;
              font-size: 13px;
              border-radius: 3px;
              background-color: $white-light;
              [dir="rtl"] & {
                margin-left: unset;
                margin-right: 10px;
              }
            }
          }
          p {
            font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
            margin-bottom: 0;
            margin-top: 3px;
            color: $content-color;
            width: calc(187px + (287 - 187) * ((100vw - 320px) / (1920 - 320)));
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        & > span {
          font-size: 12px;
          width: 24px;
          display: none;
          position: absolute;
          right: 0;
          height: 24px;
          text-align: center;
          padding: 4px;
          background-color: $theme-yellow;
          border-radius: 100%;
          [dir="rtl"] & {
            right: unset;
            left: 0;
          }
        }
        &.new {
          & > span {
            display: block;
          }
          .media-body {
            p {
              color: $title-color;
            }
          }
        }
      }
    }
  }
}
/// Message Page Css End ///

/// Chat Page Css Start ///
.Chat-page {
  .conservation {
    .media {
      align-items: flex-start;
      & > img {
        width: calc(35px + (45 - 35) * ((100vw - 320px) / (1920 - 320)));
        height: calc(35px + (45 - 35) * ((100vw - 320px) / (1920 - 320)));
        border: $card-border;
        border-radius: 100%;
        object-fit: cover;
      }
      .media-body {
        text-align: center;
        width: 80%;
        position: relative;
        padding: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320))) calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
        border: $card-border;
        box-shadow: $box-shadow;
        border-radius: 11px 11px 11px 0px;
        background-color: $theme-blue;
        margin-bottom: calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320)));
        [dir="rtl"] & {
          border-radius: 11px 11px 0px 11px;
        }
        p {
          text-align: left;
          line-height: 131.5%;
          margin: 0;
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          [dir="rtl"] & {
            text-align: right;
          }
        }
        > span {
          position: absolute;
          bottom: -23px;
          left: 0;
          font-size: 12px;
          [dir="rtl"] & {
            left: unset;
            right: 0;
          }
        }
        .waveform {
          width: 88%;
          overflow-x: hidden;

          margin-left: 35px;
          .wave {
            height: 50px !important;
            canvas {
              width: 100% !important;
              @media only screen and (max-width: 567px) {
                width: 70% !important;
              }
            }
          }
        }
        .controls {
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          a {
            .pause-m {
              display: none;
            }
            svg {
              width: 30px;
              height: 30px;
              stroke-width: 1.6px;
            }
          }
        }
        img {
          width: 70%;
          margin-right: 10px;
          margin-left: 10px;
          @media (max-width: 375px) {
            width: 63%;
          }
        }
      }
    }
    .sender-message {
      margin: calc(26px + (30 - 26) * ((100vw - 320px) / (1920 - 320))) 0;
      .media {
        justify-content: flex-end;
        margin-bottom: 20px;
        > img {
          display: none;
        }
        .media-body {
          border-radius: 11px 11px 0px 11px;
          background-color: $theme-yellow;
          margin-left: 0;
        }
        &:not(:last-child) {
          .media-body {
            > span {
              display: none;
            }
            margin-bottom: 0;
          }
        }
      }
    }
    .received-message {
      .media {
        margin-bottom: 20px;
        &:first-child {
          margin-bottom: 14px;
        }
        &:not(:first-child) {
          & > img {
            visibility: hidden;
            opacity: 0;
          }
        }
        &:not(:last-child) {
          .media-body {
            & > span {
              display: none;
            }
            margin-bottom: 0;
          }
        }
      }
      &.typing {
        .media {
          align-items: center;
          .media-body {
            background-color: $white;
            border: none;
            box-shadow: none;
            padding: 0;
            margin-bottom: 0;
            & > span {
              display: none;
            }
            p {
              font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
              color: $content-color;
              [dir="rtl"] & {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}
/// Chat Page Css End ///

/// 404 Error Page And Empty Cart Page Css Start ///
.error-404,
.empty-cart-page {
  .banner-box {
    width: 85%;
    margin: 0 auto;
    margin-top: calc(70px + (90 - 70) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;
    position: relative;
    img {
      width: 100%;
      z-index: 2;
      position: relative;
    }
    svg {
      width: 100%;
      height: 100%;
      z-index: 1;
      fill: $theme-blue;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .error {
    text-align: center;
    h2 {
      margin: calc(26px + (30 - 26) * ((100vw - 320px) / (1920 - 320))) 0 calc(11px + (15 - 11) * ((100vw - 320px) / (1920 - 320))) 0;
    }
    p {
      margin-bottom: calc(19px + (25 - 19) * ((100vw - 320px) / (1920 - 320)));
    }
  }
}
/// 404 Error Page And Empty Cart Page Css End ///

/// Coming-Soon & Maintenance Page Css start ///
.coming-soon,
.maintenance {
  position: relative;
  height: 100%;
  .top {
    position: absolute;
    top: 2%;
    left: 2%;
    animation: moveright 2s linear infinite both;
  }

  .banner-box {
    position: relative;
    margin: 0 auto;
    width: 85%;
    text-align: center;
    img {
      z-index: 2;
      position: relative;
      width: 100%;
      margin-top: calc(180px + (220 - 180) * ((100vw - 320px) / (1920 - 320)));
    }
    .bg-shape {
      top: 65%;
      left: 50%;
      transform-origin: center;
      transform: translate(-50%, -50%) rotate(5deg);
      width: 100%;
      height: 89%;
      z-index: 1;
      position: absolute;
      fill: $theme-yellow;
    }
  }
  .content-box {
    text-align: center;
    h1 {
      margin-bottom: calc(2px + (10 - 2) * ((100vw - 320px) / (1920 - 320)));
    }
    p {
      margin-bottom: 24px;
    }
  }
  .custom-form {
    text-align: center;
    margin-top: calc(27px + (35 - 27) * ((100vw - 320px) / (1920 - 320)));
    .btn {
      margin-top: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
  }
  .bottom {
    position: absolute;
    bottom: 1%;
    right: 2%;
    animation: moveleft 2s linear infinite both;
  }
}
/// Coming-Soon & Maintenance Page Css End ///

/// FAQs Page Css Start ///
.faqs {
  .banner-box {
    @include banner();
    padding: 20px 20px 0 20px;
    background-color: $theme-blue;
    > div {
      text-align: center;
      img {
        width: 85%;
      }
    }
  }
  .filter {
    margin-top: 28px;
    border: none;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    &:hover {
      overflow-x: scroll;
    }
    > div,
    li {
      &:not(:first-of-type) {
        margin-left: 15px;
        [dir="rtl"] & {
          margin-right: 15px;
          margin-left: unset;
        }
      }
      .nav-link {
        border: none;
        background-color: $theme-yellow;
        border-radius: 4px;
        color: $title-color;
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        &:hover {
          color: $title-color;
        }
      }
    }
    @media only screen and (max-width: 767px) {
      overflow-x: auto;
    }
  }
  .tab-content {
    margin-top: 15px;
  }
}
/// FAQs Page Css End ///

/// Profile Setting Page Css Start ///
.profile-settings {
  .banner-box {
    position: relative;
    @include banner();
    overflow: hidden;
    border: none;
    background-color: $theme-blue;
    .bg-size {
      &:before {
        padding-top: 28%;
        content: "";
        display: block;
      }
    }
    .media {
      width: 80%;
      position: absolute;
      top: 50%;
      left: 46%;
      transform: translate(-50%, -50%);
      justify-content: flex-start;
      [dir="rtl"] & {
        left: unset;
        right: -35%;
      }
      img {
        width: calc(60px + (100 - 70) * ((100vw - 320px) / (1920 - 320)));
        height: calc(60px + (100 - 70) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 100%;
        object-fit: cover;
      }
      .media-body {
        margin-top: 5px;
        h2 {
          color: $title-color;
          font-weight: 600;
          font-size: calc(20px + (22 - 20) * ((100vw - 320px) / (1920 - 320)));
          margin-bottom: calc(3px + (6 - 3) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }
  }
  .setting-section {
    .custom-form {
      h3 {
        margin-bottom: calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }
}
/// Profile Setting Page Css End ///

/// Team Page Css Start ///
.team {
  > p {
    text-align: justify;
    word-break: normal;
    hyphens: auto;
  }
  .member {
    .img-box {
      width: 100%;
      position: relative;
      text-align: center;
      img {
        position: relative;
        z-index: 2;
        width: 60%;
      }
      svg {
        width: 91%;
        height: 100%;
        position: absolute;
        bottom: -9.8%;
        left: 16px;
        right: 0;
        transform: rotateZ(18deg);
        z-index: 1;
        [dir="rtl"] & {
          left: unset;
          right: 16px;
        }
      }
    }
    .content-box {
      margin-top: calc(11px + (15 - 11) * ((100vw - 320px) / (1920 - 320)));
      text-align: center;
      h2 {
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(19px + (26 - 19) * ((100vw - 320px) / (1920 - 320)));
      }
      span {
        font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));
        color: $content-color;
      }
    }
  }
  .row > div {
    @include bg-shape-fill();
    @media only screen and (max-width: 375px) {
      width: 100%;
    }
  }
}
/// Team Page Css End ///

/// Review Page Css Start ///
.review-page {
  .review-section {
    .review-box {
      @include banner();
      position: relative;
      padding: 14px;
      &:not(:last-of-type) {
        margin-bottom: 18px;
      }
      .media {
        position: relative;
        img {
          width: calc(53px + (60 - 53) * ((100vw - 320px) / (1920 - 320)));
          height: calc(53px + (60 - 53) * ((100vw - 320px) / (1920 - 320)));
          border-radius: 100%;
          object-fit: cover;
        }
        .media-body {
          margin-top: 5px;
          h2 {
            color: $title-color;
          }
        }
        .star-box {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          right: 0px;
          top: 0px;
          border: $card-border;
          box-shadow: $box-shadow;
          border-radius: 4px;
          padding: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320))) calc(4px + (10 - 4) * ((100vw - 320px) / (1920 - 320)));
          width: fit-content;
          background-color: $theme-yellow;
          [dir="rtl"] & {
            left: 0;
            right: unset;
          }
          svg {
            width: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
            height: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
            stroke: $theme-yellow-dark;
            &:not(:last-of-type) {
              fill: $theme-yellow-dark;
            }
          }
        }
      }
      h3 {
        font-weight: 600;
        font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
        margin-top: 18px;
        margin-bottom: 6px;
      }
      p {
        margin-top: 15px;
        font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }
}
/// Review Page Css End ///

/// Help Page Css Start ///
.help-page {
  .banner-box {
    position: relative;
    @include banner();
    overflow: hidden;
    border: none;
    background-color: $theme-blue;
    .bg-size {
      &:before {
        padding-top: 28%;
        content: "";
        display: block;
        @media only screen and (max-width: 475px) {
          padding-top: 40%;
        }
      }
    }
    .top-header {
      top: 44%;
      left: 50%;
      width: 83%;
      transform: translate(-50%, -50%);
      position: absolute;
      text-align: center;
      h2 {
        font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 400;
        line-height: calc(43px + (50 - 43) * ((100vw - 320px) / (1920 - 320)));
      }
      .search-box {
        @include search-box();
        input {
          background-color: transparent;
        }
      }
    }
  }
  .categories {
    @include categores-slider();
  }
  .questions-section {
    .title-2 {
      margin-bottom: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    }
    .accordion {
      margin-left: -15px;
      margin-right: -15px;
      padding: 17px 20px;
      background-color: $gray-light;
      border-radius: 25px;
      .accordion-body {
        background-color: $gray-light;
      }
    }
  }
}
/// Help Page Css End ///

/// Onboarding Page Css Start ///
.onboarding-header {
  .top {
    top: 1%;
  }

  .round-top {
    top: 2.8%;
  }
}
.onboarding-slide {
  &.main-section {
    text-align: center;
    margin-top: -46%;
    margin-bottom: calc(10px + (50 - 10) * ((100vw - 320px) / (1920 - 320)));
    [dir="rtl"] & {
      direction: ltr;
    }
  }
  .onboarding {
    position: relative;
    .banner-img {
      img {
        width: 60% !important;
        margin: 0 auto !important;
      }
    }
    .round-bottom {
      position: absolute;
      bottom: 9.8%;
    }
    .dote {
      &.bottom {
        position: absolute;
        bottom: 2%;
        right: 2%;
        animation: moveleft 2s linear infinite both;
      }
    }
    .btn-box {
      .btn {
        display: inline-block !important;
      }
    }
  }
  .slick-dots {
    position: relative;
    left: 0;
    bottom: calc(-6px + (-38 + 6) * ((100vw - 320px) / (1920 - 320)));
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      position: relative;
      display: inline-block;
      height: 10px;
      width: 10px;
      margin: 0 5px;
      border-radius: 100%;
      background-color: $white;
      border: $card-border;
      padding: 0;
      cursor: pointer;
      button {
        opacity: 0;
      }
    }
    .slick-active {
      background-color: $title-color !important;
      height: 10px;
      width: 30px;
      border-radius: 20px;
    }
    @media only screen and (max-width: 475px) {
      margin-top: 15px;
    }
  }
}
/// Onboarding Footer ///
.onboarding-footer {
  text-align: center;
  .btn-box {
    a {
      display: block;
    }
    .btn {
      display: inline-block;
    }
  }
  p {
    margin-top: 100px;
  }
  .round-bottom {
    bottom: 9.8%;
  }
}
/// Onboarding Page Css End ///

/// Private Policy Page Css Start ///
.terms-condition {
  .list-section {
    list-style: disc;
    padding: 0 18px;
  }
  .fw-600 {
    font-weight: 600;
  }
  .top-heading {
    margin-bottom: calc(18px + (23 - 18) * ((100vw - 320px) / (1920 - 320)));
  }
  .title-mb {
    margin-top: -6px;
    margin-bottom: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
  }
  p {
    margin-top: -5px;
    margin-bottom: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
  }
}
/// Private Policy Page Css End ///
