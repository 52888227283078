/*=====================
  2.7 Form CSS
==========================*/

/// input style ///
.custom-form {
    input,
    textarea,
    select {
        font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
        padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) calc(44px + (47 - 44) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 8px;
        border: $card-border !important;
        box-shadow: $box-shadow !important;
        .form-control:focus {
            border-color: $border-color !important;
            box-shadow: $box-shadow !important;
        }
    }
    .input-box {
        margin-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
        position: relative;
        input,
        textarea {
            line-height: 28px;
            padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) calc(44px + (47 - 44) * ((100vw - 320px) / (1920 - 320)));
        }
        input[type="date" i]::-webkit-calendar-picker-indicator {
            position: absolute;
            left: 9px;
            z-index: 3;
            margin-left: 0;
            opacity: 0;
        }
        > svg,
        img {
            width: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
            height: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            [dir="rtl"] & {
                left: unset;
                right: 12px;
            }
        }
        &.text-box {
            > svg,
            img {
                top: calc(23px + (26 - 23) * ((100vw - 320px) / (1920 - 320)));
            }
        }
        span {
            position: absolute;
            right: 14px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 4px;
            background-color: $theme-blue;

            svg {
                width: 24px;
                height: 24px;
                transition: all 0.4s;
            }
            [dir="rtl"] & {
                right: unset;
                left: 14px;
            }
        }
        select:focus ~ span {
            svg {
                transform: rotate(90deg);
            }
        }
        &.otp-input {
            input {
                display: inline-block;
                width: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
                font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
                text-align: center;
                padding: 0;
                font-weight: 600;
                background-color: transparent;
                border: none !important;
                border-bottom: $card-border !important;
                box-shadow: none !important;
                border-radius: 0 !important;

                &:not(:last-of-type) {
                    margin-right: 8px;
                }
            }
        }
        input[type="radio"] {
            position: relative;
            margin: 2px 1rem 0 1px;
            [dir="rtl"] & {
                margin: 2px 1px 0 1rem;
            }
            cursor: pointer;
            &:before {
                transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
                transform: scale(0, 0);
                content: "";
                position: absolute;
                top: 4px;
                left: 3px;
                z-index: 1;
                width: 0.55rem;
                height: 0.55rem;
                background: $title-color;
                border-radius: 50%;
                [dir="rtl"] & {
                    left: unset;
                    right: 1px;
                }
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: -1px;
                width: 1.05rem;
                height: 1.05rem;
                background: $white;
                border: 1px solid $border-color;
                border-radius: 50%;
                [dir="rtl"] & {
                    left: unset;
                    right: -3px;
                }
            }
            &:checked {
                &:before {
                    transform: scale(1, 1);
                }
                &:after {
                    border: 1px solid $border-color;
                }
            }
        }
    }
    .form-control:disabled,
    .form-control[readonly] {
        background-color: $white;
    }
    &.top-label {
        .input-box {
            position: relative;
            &:not(:last-of-type) {
                margin-bottom: 30px;
            }
            input,
            select {
                padding: calc(8px + (11 - 8) * ((100vw - 320px) / (1920 - 320))) 15px;
            }
            .label {
                position: absolute;
                top: -9px;
                left: 20px;
                padding: 0 5px;
                font-weight: 600;
                font-size: 14px;
                background-color: $white;
                [dir="rtl"] & {
                    right: 20px;
                    left: unset;
                }
            }
        }
    }
    &.form-pill {
        .input-box {
            position: relative;

            input,
            select {
                border-radius: 100px;
                padding: calc(8px + (11 - 8) * ((100vw - 320px) / (1920 - 320))) calc(44px + (55 - 44) * ((100vw - 320px) / (1920 - 320)));
            }
            > svg,
            img {
                left: 20px;
                [dir="rtl"] & {
                    left: unset;
                    right: 20px;
                }
            }
            textarea {
                padding: calc(8px + (11 - 8) * ((100vw - 320px) / (1920 - 320))) calc(44px + (55 - 44) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }
}

/// Date Picker ///
.datepicker {
    left: 25px !important;
    &.--day-name {
        color: $theme-orange;
    }
    .datepicker--cell {
        &.-selected-,
        &.-focus-,
        &.-current- {
            background-color: $theme-blue;
            color: $title-color;
        }
    }
    [dir="rtl"] & {
        left: calc(199px + (299 - 199) * ((100vw - 320px) / (1920 - 320))) !important;
        @media only screen and (max-width: 575px) {
            left: calc(12px + (1794 - 12) * ((100vw - 320px) / (1920 - 320))) !important;
        }
        @media only screen and (max-width: 375px) {
            left: 12px !important;
        }
    }
}
.datepicker--nav-action {
    path {
        stroke: $title-color;
    }
}
