/*=====================
  4.4 Element Page CSS
==========================*/
.element-pages {
  .ratio_asos {
    .bg-size {
      &:before {
        padding-top: 0%;
      }
    }
  }
  .banner {
    position: relative;
    border-radius: 10px;
    border: $card-border;
    box-shadow: $box-shadow;
    background-color: rgba($theme-success, 0.2);
    padding: 36px 20px;
    .banner-content {
      text-align: center;
      h2 {
        font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 8px;
      }
      p {
        color: $content-color;
        margin-bottom: 0;
      }
    }
    .badges-round {
      position: absolute;
      padding: 3px 10px;
      top: 8px;
      right: 8px;
      color: $white;
      font-size: 14px;
      [dir="rtl"] & {
        right: unset;
        left: 8px;
      }
    }
  }
  .breadcrumb-wrap {
    h3 {
      margin-bottom: 10px;
    }
    ol {
      margin-bottom: 0;
    }
    > h3 {
      font-weight: 600;
    }
  }
  .accordian-wrap {
    @include dashed-border-bottom();
    > h3 {
      font-weight: 600;
    }
  }
  .header-wrap {
    border-radius: 3px;
    margin-bottom: 25px;
    .header {
      box-shadow: 0px -1px 4px rgba($border-color, 0.1);
    }
    > h3 {
      font-weight: 600;
    }
  }
  &.menu-style {
    h3 {
      font-weight: 600;
    }
  }
  .footer-wrap {
    padding: 0;
    position: relative;
    margin-bottom: 25px;
    &:not(:first-of-type) {
      box-shadow: 0px -1px 4px rgba($border-color, 0.1);
      border-radius: 3px;
      margin-bottom: 25px;
    }
  }
  .button-wrap {
    > h3 {
      font-weight: 600;
      margin-bottom: 12px;
    }
    .polar-chart {
      margin: 0 auto;
    }
    .btn-box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .btn {
        margin: 5px;
      }
    }
  }
  .pagination-wrap {
    text-align: center;
  }
  .grid-wrap {
    h3 {
      font-weight: 600;
    }
    .row {
      > div {
        > div {
          text-align: center;
          padding: 10px;
          background-color: $white;
          border: $card-border;
          box-shadow: $box-shadow;
          border-radius: 10px;
        }
      }
    }
  }
  .text-resizer {
    text-align: center;
  }
  &.infinite-scroll {
    h3 {
      font-weight: 600;
    }
  }
  .heading-wrap {
    > h3 {
      margin-bottom: 15px;
      font-weight: 600;
    }
    .heading {
      padding: 10px;
      border: $card-border;
      box-shadow: $box-shadow;
      border-radius: 10px;
      .icon-group {
        background-image: $dark-gradient;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        border-radius: 10px;
        flex-wrap: wrap;
        svg {
          width: 25px;
          height: 25px;
          margin: 5px 0;
          &:not(:last-of-type) {
            margin-right: 15px;
            [dir="rtl"] & {
              margin-left: 15px;
              margin-right: unset;
            }
          }
        }
        img {
          margin-right: 15px;
          filter: invert(1);
          width: 30px;
          height: 30px;
          [dir="rtl"] & {
            margin-left: 15px;
            margin-right: unset;
          }
        }
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: 600;
        line-height: 37px;
        color: $title-color;
      }
      p {
        margin-bottom: 5px;
      }
    }
    .pre {
      max-height: fit-content;
      margin-top: 20px;
      border: $card-border;
      box-shadow: $box-shadow;
      border-radius: 10px;
      overflow: hidden;
      pre {
        background-color: $gray-light;
        code {
          margin-top: -20px;
        }
      }
    }
    .-mb-10 {
      margin-bottom: -10px;
    }
    h4 {
      font-weight: 600;
    }
  }
  .map-wrap {
    h3 {
      font-weight: 600;
    }
  }

  .pera-wrap {
    padding: 10px;
    border: $card-border;
    box-shadow: $box-shadow;
    border-radius: 10px;
    p {
      margin-top: 0;
      margin-bottom: 8px;
    }
  }

  .list {
    list-style: disc;
    li {
      margin-left: 15px;
      margin-top: 15px;
      [dir="rtl"] & {
        margin-left: unset;
        margin-right: 15px;
      }
      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
      p {
        margin: 0;
      }
      a {
        text-decoration: underline;
        color: $theme-dark-orange;
      }
    }
  }

  .action-list {
    li {
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
      a {
        display: block;
        color: $title-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        svg {
          width: 23px;
          height: 23px;
        }
      }
    }
  }
  .toast-list-wrap {
    h3 {
      font-weight: 600;
      font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .toast-list {
      li {
        margin-top: 15px;
        margin-bottom: 10px;
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 15px;
          border: $card-border;
          box-shadow: $box-shadow;
          border-radius: 10px;
        }
      }
    }
  }
  .typo {
    h4 {
      font-weight: 600;
    }
    h3 {
      font-weight: 600;
    }
  }
  .wrap {
    h4 {
      font-weight: 600;
    }
  }
  .notification-list {
    li {
      margin-top: 15px;
      margin-bottom: 10px;
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 15px;
        border: $card-border;
        box-shadow: $box-shadow;
        border-radius: 10px;
      }
    }
  }
  .notification-list,
  .action-list,
  .toast-list {
    li {
      svg {
        margin-right: -7px;
        [dir="rtl"] & {
          margin-right: unset;
          margin-left: -7px;
          transform-origin: center;
          transform: rotate(180deg);
        }
      }
    }
  }
  &.notifi-page {
    h3 {
      font-weight: 600;
    }
  }
  &.text-resize {
    h3 {
      font-weight: 600;
    }
  }
  .pricing-table-wrap {
    > h3 {
      font-weight: 600;
      margin-bottom: 15px;
    }
    .row {
      > div {
        @media only screen and (max-width: 400px) {
          width: 100%;
        }
      }
    }
  }
  &.table-page {
    h3 {
      font-weight: 600;
    }
  }
  .pricing-table-wrap {
    .pricing-slide {
      [dir="rtl"] & {
        direction: ltr;
      }
      .slick-list {
        margin-left: -10px;
        margin-right: -10px;
        padding: 4px 0;
        .slick-slide {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}
