/*=====================
  2.12 Notification CSS
==========================*/

/// notification Start ///
.notification {
    border: $card-border;
    border-radius: 10px;
    width: calc(290px + (570 - 290) * ((100vw - 320px) / (1920 - 320)));
    position: fixed;
    left: 50%;
    top: -300px;
    transform: translateX(-50%);
    background-color: $white;
    transition: all 0.3s linear;
    .notification-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba($border-color, 0.1);
        padding: 8px 15px;
        span {
            font-size: 13px;
            color: $content-color;
            font-weight: normal;
        }
        .notifi-name {
            display: flex;
            align-items: center;
            svg {
                width: 16px;
                height: 16px;
                margin-right: 5px;
                [dir="rtl"] & {
                    margin-left: 5px;
                    margin-right: unset;
                }
            }
        }
    }
    .main {
        padding: 15px;
        h2 {
            font-size: 15px;
            margin-bottom: 2px;
        }
        p {
            font-size: 14px;
            color: $content-color;
            margin-bottom: 0;
        }
    }
    &.show {
        top: 10px;
    }
    &.notifi-a1 {
        .main {
            .media {
                align-items: flex-start;
                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                    border: $card-border;
                    padding: 2px;
                }
                .media-body {
                    width: calc(100% - 50px);
                    h2 {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        span {
                            font-size: 13px;
                            color: $content-color;
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }
    &.notifi-a2 {
        .main {
            .media {
                align-items: flex-start;
                padding-bottom: 15px;
                width: 100%;
                svg {
                    flex: 0 0 24px;
                    width: 24px;
                    height: 24px;
                }
                .media-body {
                    width: calc(100% - 24px);
                    h2 {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        span {
                            font-size: 13px;
                            color: $content-color;
                            font-weight: normal;
                        }
                    }
                }
            }
            .option {
                display: flex;
                align-items: center;
                justify-content: space-between;

                padding-top: 15px;
                border-top: 1px solid rgba($border-color, 0.1);
                span {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    color: $content-color;
                    cursor: pointer;

                    svg {
                        width: 15px;
                        height: 15px;
                        margin-right: 5px;
                        [dir="rtl"] & {
                            margin-left: 5px;
                            margin-right: unset;
                        }
                    }
                }
            }
        }
    }
    &.notifi-call {
        @extend .notifi-a1;
        .media {
            padding-bottom: 15px;
            align-items: center;
        }
        .option {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 15px;
            border-top: 1px solid rgba($border-color, 0.1);
            span {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: $content-color;
                cursor: pointer;

                svg {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                    [dir="rtl"] & {
                        margin-left: 5px;
                        margin-right: unset;
                    }
                }
            }
        }
    }

    &.full {
        width: 100%;
        max-width: 600px;
        border-radius: 0;
        border: none;
    }
}
/// notification End ///
