/*=====================
  2.8 General CSS
==========================*/

/// Shape ///
.round-top {
    position: absolute;
    top: 6.8%;
    right: 13.5%;
    animation: bouncing-top 2s linear infinite both;
    img {
        height: auto;
    }
}
.round-bottom {
    position: absolute;
    bottom: 17%;
    left: 13.5%;
    animation: 2s bouncing-bottom linear infinite both;
}
.dote-bottom {
    position: absolute;
    bottom: 10%;
    right: 2%;
    animation: moveleft 2s linear infinite both;
}

/// Dumbels Shape ///
.shape2 {
    position: absolute;
    width: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: translate(-50%, -50%);
    bottom: -68px;
    z-index: 1;
    .circle {
        position: relative;
        z-index: 1;
        width: 12px;
        display: inline-block;
        height: 12px;
        background-color: $title-color;
        border-radius: 100%;
    }
    .line {
        margin: -7px 0;
        position: relative;
        width: 5px;
        z-index: 2;
        height: calc(48px + (48 - 48) * ((100vw - 320px) / (1920 - 320)));
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: 5px;
    }
}
.shape2-left {
    left: 15.5%;
}
.shape2-right {
    right: 15.5%;
}

/// progress border ///
.track-border {
    .m-dashed {
        display: inline-block;
        width: 4px;
        height: 8px;
        border-radius: 100px;
        background-color: $border-color;
        position: relative;
        &::before,
        &::after {
            position: absolute;
            content: "";
            width: 100%;
            border-radius: 100px;
            height: 100%;
            background-color: rgba($border-color, 0.1);
        }
        &::before {
            bottom: 12px;
        }
        &::after {
            top: 12px;
        }
    }
}

/// Catagories Menu ///
.catagories-menu {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background-image: $dark-gradient;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    bottom: 86px;
    left: 25px;
    font-size: 12px;
    display: none;
    color: $white;
    [dir="rtl"] & {
        left: unset;
        right: 25px;
    }
    @media only screen and (max-width: 475px) {
        display: flex;
    }
    svg {
        width: 20px;
        height: 20px;
        stroke: $white;
    }
}

/// Calender Arrow ///
.datepicker--nav-action {
    svg {
        [dir="rtl"] & {
            transform-origin: center;
            transform: rotate(180deg);
        }
    }
}

/// Apex chart ///
.apexcharts-legend-series {
    margin-top: 8px !important;
    .apexcharts-legend-marker {
        border: $card-border;
        margin-right: 8px !important;
        [dir="rtl"] & {
            margin-left: 8px;
            margin-right: unset;
        }
    }
}
/// Rtl Direction Ltr ///
.dir-ltr {
    [dir="rtl"] & {
        direction: ltr;
    }
}
.apexcharts-canvas {
    margin: 0 auto !important;
}
.apexcharts-menu-icon {
    display: none;
}
.apexcharts-toolbar {
    display: none !important;
}

/// Circle Loader ///
.circle-loader {
    position: fixed;
    bottom: 100px;
    left: 50%;
    width: 40px;
    height: 40px;
    transform: translateX(-50%);
    &.hidden {
        display: none;
    }
}

/// Leaf Map Start ///
.leaf-map {
    z-index: 1;
    border: $card-border;
    box-shadow: $box-shadow;
    border-radius: 15px;
    width: 100%;
    height: calc(180px + (240 - 180) * ((100vw - 320px) / (1920 - 320)));
    .leaflet-touch {
        .leaflet-bar {
            a {
                width: 24px;
                height: 25px;
                line-height: 25px;
            }
        }
    }
    .leaflet-control-attribution {
        display: none !important;
    }
}

/// Google Map & Bing Map ///
.map-iframe {
    overflow-y: hidden;
    overflow-x: hidden;
    object-fit: cover;
    width: 100% !important;
    height: calc(180px + (240 - 180) * ((100vw - 320px) / (1920 - 320)));
    border: $card-border;
    box-shadow: $box-shadow;
    border-radius: 15px;
}
/// Infinite Gallery ///
.infinite-gallery {
    .img-box {
        .gallery-img {
            width: 100%;
            height: 100%;
            padding: 5px;
            border: $card-border;
            box-shadow: $box-shadow;
            border-radius: 10px;
            object-fit: cover;
        }
    }
}

/// Video Embed Start ///
.videobox {
    padding: 7px !important;

    .video-iframe {
        display: block;
        width: 100%;
        height: calc(170px + (310 - 170) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 10px;
    }
    .video-local {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 10px;
    }
}
.bg-videobox {
    position: relative;
    display: block;
    width: 100%;
    height: calc(170px + (310 - 170) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 10px;
    overflow: hidden;

    .bg-video {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        height: auto;
        width: auto;
        z-index: -100;
    }
    .content {
        background-color: rgba($title-color, 0.2);
        padding: 10px;
        border-radius: 10px;
        h4,
        p {
            color: $white !important;
        }
    }
}
/// Video Embed End ///
