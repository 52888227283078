/*=====================
  2.11 Nav Tabs CSS
==========================*/

/// E-Commerce Tabs ///
.nav-tabs-e {
    border: none;
    padding: 3px 23px;
    background-color: $gray-light;
    border-radius: 100px;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    .nav-item {
        .nav-link {
            border: none;
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            font-weight: normal;
            color: $title-color;
            white-space: nowrap;
            &:hover,
            &:focus {
                border: none;
                isolation: none;
            }
            &.active {
                color: $active-color;
                background-color: transparent;
                border: none;
                font-weight: 600;
            }
        }
        &.show {
            .nav-link {
                color: $active-color;
                background-color: transparent;
                border: none;
            }
        }
    }
    &.blog-nav {
        .nav-item {
            .nav-link {
                color: $title-color;
                font-weight: 400;
                &.active {
                    color: $title-color;
                    font-weight: 600;
                }
            }
        }
    }
}
