/*=====================
 2.3 Animation CSS
==========================*/

/// Bouncing ///
@keyframes bouncing-top {
    0% {
        transform: translateY(10px);
    }
    50% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(10px);
    }
}
@keyframes bouncing-bottom {
    0% {
        transform: translateY(10px);
    }
    50% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(10px);
    }
}

/// left > right ///
@keyframes moveright {
    0% {
        left: 2%;
    }
    50% {
        left: 3.5%;
    }
    100% {
        left: 2%;
    }
}

/// left < right ///
@keyframes moveleft {
    0% {
        right: 2%;
    }
    50% {
        right: 3.5%;
    }
    100% {
        right: 2%;
    }
}

/// Preloader Start ///
@keyframes x-axis {
    0% {
        transform: translate(-60px * 1.6);
    }
    100% {
        transform: translate(60px * 1.6);
    }
}

@keyframes y-axis {
    0% {
        transform: translateY(60px * 0.7);
    }
    100% {
        transform: translateY(-60px * 1.1) scale(0.8);
        background: $theme-blue;
    }
}

@keyframes x-axis-lateral {
    0% {
        transform: translate(-60px * 0.6);
    }
    100% {
        transform: translate(60px * 0.6);
    }
}

@keyframes y-axis-lateral {
    0% {
        transform: translateY(60px/5);
    }
    100% {
        transform: translateY(-60px);
        background: $theme-blue;
    }
}
/// Pre Loader End ///
