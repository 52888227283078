/*=====================
 2.13 Pagination CSS 
==========================*/

/// Pagination ///
.pagination {
    list-style: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-top: 10px;
    box-shadow: $box-shadow;
    border-radius: 5px;
    li {
        display: inline;
        text-align: center;
    }
    a {
        float: left;
        display: block;
        font-size: 14px;
        text-decoration: none;
        padding: 5px 12px;
        color: $title-color;
        margin-left: -1px;
        border: 1px solid transparent;
        line-height: 1.5;
        &.active {
            cursor: default;
        }
        &:active {
            outline: none;
        }
    }
}

/// Style 1 Start ///
.modal-1 {
    li {
        &:first-child {
            a {
                border-radius: 5px 0 0 5px;
                [dir="rtl"] & {
                    border-radius: 0 5px 5px 0;
                }
            }
        }
        &:last-child {
            a {
                border-radius: 0 5px 5px 0;
                [dir="rtl"] & {
                    border-radius: 5px 0 0 5px;
                }
            }
        }
    }
    a {
        border-color: $border-color;
        color: $title-color;
        background: $white;
        &:hover {
            background: $white-light;
        }
        &.active,
        &:active {
            border-color: $border-color;
            background-image: $dark-gradient;
            color: $white;
        }
    }
}
/// Style 1 End ///

/// Style 2 Start ///
.modal-2 {
    border-radius: 100px;
    li {
        &:first-child {
            a {
                border-radius: 100px 0 0 100px;
                [dir="rtl"] & {
                    border-radius: 0 100px 100px 0;
                }
            }
        }
        &:last-child {
            a {
                border-radius: 0 100px 100px 0;
                [dir="rtl"] & {
                    border-radius: 100px 0 0 100px;
                }
            }
        }
    }
    a {
        border-color: $border-color;
        color: $title-color;
        background: $white;
        &:hover {
            background: $white-light;
        }
        &.active,
        &:active {
            border-color: $border-color;
            background-image: $dark-gradient;
            color: $white;
        }
    }
}
/// Style 2 End ///

/// Style 3 Start ///
.modal-3 {
    box-shadow: none;
    li {
        a {
            border-color: $border-color;
            color: $title-color;
            background: $white;
            box-shadow: $box-shadow;
            border-radius: 5px;

            &.active {
                background-image: $dark-gradient;
                color: $white;
            }
        }
        &:not(:last-child) {
            margin-right: 6px;
            [dir="rtl"] & {
                margin-left: 6px;
                margin-right: unset;
            }
        }
    }
}
/// Style 3 End ///

/// Style 4 Start ///
.modal-4 {
    box-shadow: none;
    li {
        &:not(:last-of-type) {
            margin-right: 8px;
            [dir="rtl"] & {
                margin-left: 8px;
                margin-right: unset;
            }
        }
        a {
            padding: 0;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba($theme-orange, 0.5);
            svg {
                width: 18px;
                height: 18px;
                stroke-width: 2px;
                [dir="rtl"] & {
                    transform-origin: center;
                    transform: rotate(180deg);
                }
            }
            &:hover,
            &:focus {
                background-color: $theme-orange;
                border: $card-border;
                box-shadow: $box-shadow;
            }
            &.active,
            &:active {
                box-shadow: $box-shadow;
                background-color: $theme-orange;
                border: $card-border;
            }
        }
    }
}
/// Style 4 End ///

/// Style 5 End ///
.modal-5 {
    @extend .modal-4;
    li {
        &:not(:last-of-type) {
            margin-right: 8px;
            [dir="rtl"] & {
                margin-left: 8px;
                margin-right: unset;
            }
        }
        a {
            background-color: $white;
            border: $card-border;
            box-shadow: $box-shadow;
        }
    }
}
/// Style 5 End ///

/// Style 6 Start ///
.modal-6 {
    box-shadow: none;
    li {
        @include bg-color;
        padding: 0;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 30px;
        border-radius: 100%;
        border: $card-border;
        &:not(:last-of-type) {
            margin-right: 8px;
            [dir="rtl"] & {
                margin-left: 8px;
                margin-right: unset;
            }
        }
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            line-height: 6px;
            svg {
                width: 18px;
                height: 18px;
                stroke-width: 2px;
                [dir="rtl"] & {
                    transform-origin: center;
                    transform: rotate(180deg);
                }
            }
        }

        &.active {
            box-shadow: $box-shadow;
            width: 40px;
            height: 40px;
            a {
                font-size: 18px;
                color: $title-color;
            }
        }
    }
}
/// Style 6 End ///

/// Style 7 Start ///
.modal-7 {
    box-shadow: none;

    li {
        &:not(:last-of-type) {
            margin-right: 8px;
            [dir="rtl"] & {
                margin-left: 8px;
                margin-right: unset;
            }
        }
        .item {
            color: $theme-yellow-dark;
            background-image: $dark-gradient;
            font-size: 15px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 35px;
            width: 35px;
            border: none;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            z-index: 1;
            transition: all 0.3s ease 0s;
            padding: 0;

            &:hover,
            &:focus,
            &.active:hover,
            &.active {
                color: $title-color;
                background: transparent;
                box-shadow: $box-shadow;
                svg {
                    stroke: $title-color;
                }
            }
            &::before {
                content: "";
                height: 100%;
                width: 100%;
                border: 5px solid $theme-yellow-dark;
                border-top-color: transparent;
                border-right-color: transparent;
                border-radius: 50%;
                opacity: 0;
                transform: rotate(-155deg);
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
                transition: all 0.3s ease 0s;
            }
            &:hover,
            &:focus,
            &.active:hover,
            &.active {
                &::before {
                    opacity: 1;
                    transform: rotate(-70deg);
                }
            }
        }
        .prev,
        .next {
            font-size: 20px;
            svg {
                width: 18px;
                height: 18px;
                stroke-width: 2px;
                stroke: $theme-yellow-dark;
                [dir="rtl"] & {
                    transform-origin: center;
                    transform: rotate(180deg);
                }
            }
        }
    }
}
/// Style 7 End ///

/// Style 8 Start ///
.modal-8 {
    box-shadow: none;
    li {
        a {
            &.active {
                background-image: $dark-gradient;
                color: $white;
                border-radius: 5px;
            }
            svg {
                [dir="rtl"] & {
                    transform-origin: center;
                    transform: rotate(180deg);
                }
            }
            &.dote {
                font-size: 20px;
            }
        }
    }
}
/// Style 8 End ///

/// Style 9 Start ///
.modal-9 {
    padding: 10px;
    border: $card-border;
    li {
        &:first-child {
            a {
                border-radius: 5px 0 0 5px;
                [dir="rtl"] & {
                    border-radius: 0 5px 5px 0;
                }
            }
        }
        &:last-child {
            a {
                border-radius: 0 5px 5px 0;
                [dir="rtl"] & {
                    border-radius: 5px 0 0 5px;
                }
            }
        }
    }
    a {
        border-color: $border-color;
        color: $title-color;
        background: $white;
        padding: 5px calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320)));
        &:hover {
            background: $white-light;
        }
        &.active,
        &:active {
            border-color: $border-color;
            background-image: $dark-gradient;
            color: $white;
        }
    }
}
/// Style 9 End ///
