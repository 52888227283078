/// Bg Colors Class Start ///
.bg-purple {
    background-color: $theme-purple !important;
}
.bg-light-purple {
    background-color: $theme-light-purple !important;
}
.bg-green {
    background-color: $theme-green !important;
}
.bg-yellow {
    background-color: $theme-yellow !important;
}
.bg-yellow-dark {
    background-color: $theme-yellow-dark !important;
}
.bg-blue {
    background-color: $theme-blue !important;
}
.bg-orange {
    background-color: $theme-orange !important;
}
.bg-black {
    background-color: $black !important;
}
.bg-theme-success {
    background-color: $theme-success;
}
/// Bg Colors Class End ///

/// Media Class ///
.media {
    display: flex;
    align-items: center;

    .media-body {
        margin-left: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
        [dir="rtl"] & {
            margin-right: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
            margin-left: unset;
        }
    }
}

/// Overlay ///
.overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($title-color, 0.8);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    transition: all 0.5s ease;
    &.show {
        visibility: visible;
        opacity: 1;
    }
}

.fill {
    fill: $dark-to;
}
.main-wrap {
    padding: 0px 15px 15px 15px;
}
.badges-round {
    padding: 4px 8px;
    border-radius: 100px;
}
.badges-square {
    padding: 4px 8px;
    border-radius: 5px;
}
.badges-lg-square {
    padding: 8px 15px;
    color: $white;
    font-weight: 600;
    border-radius: 5px;
}
.top {
    position: absolute;
    top: 2%;
    left: 2%;
    animation: moveright 2s linear infinite both;
}

//// Section Padding Classes ////
.p-tb-sm {
    padding-top: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
}
.p-t-sm {
    padding-top: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
}
.p-b-sm {
    padding-bottom: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
}

.p-tb-md {
    padding-top: calc(26px + (34 - 26) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(26px + (34 - 26) * ((100vw - 320px) / (1920 - 320)));
}
.p-t-md {
    padding-top: calc(26px + (34 - 26) * ((100vw - 320px) / (1920 - 320)));
}

.p-t-xl {
    padding-top: calc(38px + (55 - 38) * ((100vw - 320px) / (1920 - 320)));
}
.p-b-lg {
    padding-bottom: 120px !important;
}

/// Section  Margin Classes ////
.mt-small {
    margin-top: calc(5px + (20 - 5) * ((100vw - 320px) / (1920 - 320)));
}
.m-b-title1 {
    margin-bottom: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
}
.mb-md {
    margin-bottom: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
}
.m-tb-md {
    margin-top: calc(35px + (55 - 35) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(35px + (55 - 35) * ((100vw - 320px) / (1920 - 320)));
}
.m-t-md {
    margin-top: calc(28px + (55 - 28) * ((100vw - 320px) / (1920 - 320)));
}
.mb-large {
    margin-bottom: calc(70px + (90 - 70) * ((100vw - 320px) / (1920 - 320)));
}
.mb-xl-large {
    margin-bottom: 120px;
}
