/*=====================
  4.1 Authentic Pages CSS
==========================*/

/// Login Page Css Start ///
.login-page {
    .round-top {
        top: 2.8%;
    }
    .top {
        top: 1%;
    }
    .ratio-83 {
        .bg-size {
            &:before {
                padding-top: 74%;
                content: "";
                display: block;
            }
        }
    }
    .banner-img {
        margin-top: -55%;
        text-align: center;
        img {
            width: 65%;
        }
    }
    .login-section {
        .custom-form {
            text-align: center;
            h1 {
                margin-bottom: calc(10px + (24 - 10) * ((100vw - 320px) / (1920 - 320)));
                text-align: left;
                [dir="rtl"] & {
                    text-align: right;
                }
            }
            p {
                text-align: left;
                [dir="rtl"] & {
                    text-align: right;
                }
            }

            > span {
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: left;
                margin-bottom: calc(24px + (40 - 34) * ((100vw - 320px) / (1920 - 320)));
                [dir="rtl"] & {
                    justify-content: right;
                }
                a {
                    text-decoration: underline;
                    color: $content-color;
                    margin-left: 10px;
                    [dir="rtl"] & {
                        margin-right: 10px;
                        margin-left: unset;
                    }
                    svg {
                        width: 21px;
                        height: 21px;
                    }
                }
            }

            .forgot {
                margin-top: -12px;
                text-align: right;
                display: block;
                [dir="rtl"] & {
                    text-align: left;
                }
            }
            .btn {
                width: 100%;
                margin-bottom: 22px;
            }

            .countdown {
                margin-bottom: calc(34px + (50 - 34) * ((100vw - 320px) / (1920 - 320)));
                ul {
                    li {
                        width: calc(70px + (120 - 70) * ((100vw - 320px) / (1920 - 320)));
                        height: calc(33px + (56 - 33) * ((100vw - 320px) / (1920 - 320)));
                        padding: calc(0px + (13 - 0) * ((100vw - 320px) / (1920 - 320))) 13px;
                        margin-right: 0;
                    }
                }
            }
        }
        .otp-countdown {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: calc(34px + (50 - 34) * ((100vw - 320px) / (1920 - 320)));
            a {
                text-decoration: underline;
                color: $content-color;
            }
        }
    }
    .socila-section {
        margin-top: 30px;
        > span {
            width: 100%;
            padding: 2px;
            text-align: center;
            display: block;
            direction: ltr;
            @include dashed-border-bottom();
            > span {
                position: absolute;
                transform: translate(-50%, -50%);
                background-color: $white;
                padding: 8px 10px;
            }
        }
        ul {
            margin-top: calc(23px + (40 - 23) * ((100vw - 320px) / (1920 - 320)));
            display: flex;
            align-items: center;
            justify-content: center;
            li {
                padding: 10px;
                border-radius: 100%;
                background-image: $dark-gradient;
                box-shadow: 0px 4px 4px rgba($black, 0.4);
                &:not(:last-child) {
                    margin-right: 10px;
                    [dir="rtl"] & {
                        margin-left: 10px;
                        margin-right: unset;
                    }
                }
                a {
                    svg {
                        fill: $white;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
    .round-bottom {
        bottom: 7%;
    }
    .dote-bottom {
        bottom: 1%;
    }
}
/// Login Page Css End ///
