/*=====================
  4.2 Blog Pages CSS
==========================*/

/// Blog Home Page Css Start ///
.blog-home {
  h2 {
    line-height: calc(25px + (35 - 25) * ((100vw - 320px) / (1920 - 320)));
  }
  .banner-section {
    [dir="rtl"] & {
      direction: ltr;
    }
    > div {
      .banner-box {
        text-align: center;
        .img-box {
          overflow: hidden;
          border: $card-border;
          box-shadow: $box-shadow;
          border-radius: 6px;
          padding: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320)));
          img {
            border-radius: 6px;
            width: 100%;
          }
        }
        h2 {
          margin-bottom: -7px;
          margin-top: 5px;
        }
      }
    }
    .slick-list {
      padding-bottom: 4px !important;
      margin-bottom: -4px;
      margin-left: -10px;
      margin-right: -10px;
      padding-left: 0 !important;
      .slick-slide {
        padding: 0 10px;
      }
    }
  }
  .article-section {
    .reading-box {
      border: $card-border;
      box-shadow: $box-shadow;
      display: block;
      border-radius: 8px;
      padding: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320)));
      .media {
        > div {
          &:first-of-type {
            width: 70px;
            height: 70px;
            border-radius: 8px;
            overflow: hidden;
            img {
              object-fit: cover;
              width: 100%;
            }
          }
        }
        .media-body {
          h4 {
            font-weight: 600;
            margin-bottom: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 300px;
            @media only screen and (max-width: 567px) {
              width: calc(120px + (900 - 120) * ((100vw - 320px) / (1920 - 320)));
            }
            @media only screen and (max-width: 475px) {
              line-height: 16px;
            }
          }
          > div {
            @include content-detail();
            @media only screen and (max-width: 475px) {
              flex-direction: column;
              align-items: flex-start;
            }
            span {
              &:first-of-type {
                @media only screen and (max-width: 475px) {
                  margin-bottom: 4px;
                }
              }
            }
          }
        }
        .ko-progress-circle {
          margin-left: auto;
          margin-right: 15px;
          [dir="rtl"] & {
            direction: ltr;
            margin-left: unset;
            margin-right: auto;
          }
        }
      }
    }
  }
  .categories {
    @include scroll-r-nav();
    ul {
      li {
        @include bg-color();
      }
    }
    .videobox {
      position: relative;
      margin-top: calc(26px + (36 - 26) * ((100vw - 320px) / (1920 - 320)));
      padding: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320)));
      border: $card-border;
      box-shadow: $box-shadow;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      .video-iframe {
        width: 100%;
        height: calc(170px + (320 - 170) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 15px;
        display: none;
      }
      .video-thumb {
        width: 100%;
        border-radius: 15px;
      }
      .play {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background-image: $dark-gradient;
        svg {
          width: 25px;
          stroke: $white;
          height: 25px;
          margin-left: 4px;
        }
      }
    }
  }
  .recent-section {
    .recent-box {
      .recent {
        &:not(:first-of-type) {
          padding-top: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
        }
        &:not(:last-of-type) {
          padding-bottom: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
          @include dashed-border-bottom();
        }
        .media {
          @include blog-list();
          @media only screen and (max-width: 375px) {
            align-items: flex-start;
          }
          .media-body {
            > div {
              @media only screen and (max-width: 375px) {
                flex-direction: column;
                align-items: flex-start;
              }
              span {
                &:first-of-type {
                  @media only screen and (max-width: 375px) {
                    margin-bottom: 6px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
/// Blog Home Page Css End ///

/// Search Box Start ///
.blog-home,
.blog-style1,
.blog-style2,
.blog-style3,
.blog-style4 {
  .search-box {
    @include search-box();
  }
}
/// Search Box End ///

/// Blog List Style 1 Page Css Start ///
.blog-style1 {
  .tab-content {
    .topic-box {
      .topic {
        margin-top: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
        .media {
          position: relative;
          @include blog-list();
          @media only screen and (max-width: 375px) {
            align-items: flex-start;
          }
          .media-body {
            > div {
              @media only screen and (max-width: 375px) {
                flex-direction: column;
                align-items: flex-start;
              }
              span {
                &:first-of-type {
                  @media only screen and (max-width: 375px) {
                    margin-bottom: 6px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
/// Blog List Style 1 Page Css End ///

/// Blog List Style 2 Page Css Start ///
.blog-style2 {
  .blog-grid {
    .product-card {
      padding: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320)));
      .img-box {
        display: block;
        > span {
          top: 8px;
          right: 8px;
          position: absolute;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          background-image: $dark-gradient;
          [dir="rtl"] & {
            right: unset;
            left: 8px;
          }
          svg {
            top: unset;
            left: unset;
            right: unset;
            position: relative;
            width: 16px;
            height: 16px;
            stroke: $white;
            &.fill {
              fill: $white;
            }
          }
        }
        .product-detail {
          text-align: left;
          position: absolute;
          bottom: 0;
          background-image: $dark-gradient;
          border-radius: 15px;
          width: 100%;
          padding: 8px;

          [dir="rtl"] & {
            text-align: right;
          }

          p {
            font-size: calc(14px + (17 - 14) * ((100vw - 320px) / (1920 - 320)));
            margin: 0;
            color: $white-light;
            font-weight: 600;
            @media only screen and (max-width: 375px) {
              font-size: 16px;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 375px) {
      .row {
        > div {
          width: 100%;
        }
      }
    }
  }
}
/// Blog List Style 2 Page Css End ///

/// Blog List Style 3 Page Css Star ///
.blog-style3 {
  .search-box {
    @include search-box();
  }
  .blog-list {
    .topic-box {
      .topic {
        padding: 10px;
        border-radius: 15px;
        box-shadow: $box-shadow;
        border: $card-border;
        &:not(:first-of-type) {
          margin-top: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
        }
        .media {
          position: relative;
          @include blog-list();
          @media only screen and (max-width: 375px) {
            align-items: flex-start;
            .media-body {
              > div {
                @media only screen and (max-width: 375px) {
                  flex-direction: column;
                  align-items: flex-start;
                }
                span {
                  &:first-of-type {
                    @media only screen and (max-width: 375px) {
                      margin-bottom: 6px;
                    }
                  }
                }
              }
            }
          }
          > div {
            &:first-of-type {
              border: unset;
              box-shadow: unset;
              padding: 0;
              height: 90px;
            }
          }
        }
        &:nth-of-type(2n + 2) {
          .media {
            flex-direction: row-reverse;
            text-align: end;
            @media only screen and (max-width: 375px) {
              align-items: flex-start;
              .media-body {
                > div {
                  @media only screen and (max-width: 375px) {
                    align-items: flex-end;
                  }
                  span {
                    &:first-of-type {
                      @media only screen and (max-width: 375px) {
                        margin-bottom: 6px;
                        margin-right: 0;
                        margin-left: 0;
                      }
                    }
                  }
                }
              }
            }
            .media-body {
              margin-left: unset;
              margin-right: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
              [dir="rtl"] & {
                margin-left: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
                margin-right: unset;
              }
              > div {
                justify-content: end;
                > span {
                  [dir="rtl"] & {
                    flex-direction: row-reverse;
                    svg {
                      margin-right: 8px;
                      margin-left: unset;
                    }
                  }
                }
              }
              > span {
                left: 10px;
                right: unset;
                [dir="rtl"] & {
                  left: unset;
                  right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
/// Blog List Style 3 Page Css End ///

/// Blog List Style 4 Page Css Start ///
.blog-style4 {
  .ratio_asos {
    .bg-size {
      &:before {
        padding-top: 26.777%;
      }
    }
  }
  .blog-list {
    .banner-box {
      &:not(:first-of-type) {
        padding-top: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
      }
      &:not(:last-of-type) {
        @include dashed-border-bottom();
        padding-bottom: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
      }

      h2 {
        margin-bottom: calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)));
      }
      .banner {
        padding: calc(4px + (7 - 4) * ((100vw - 320px) / (1920 - 320)));
        border: $card-border;
        box-shadow: $box-shadow;
        border-radius: 15px;
        > div {
          border-radius: 15px;
        }
      }
      .banner-content {
        p {
          margin-top: 16px;
          margin-bottom: 16px;
          color: $content-color;
        }
        .media {
          > div {
            &:first-of-type {
              width: calc(48px + (60 - 48) * ((100vw - 320px) / (1920 - 320)));
              height: calc(48px + (60 - 48) * ((100vw - 320px) / (1920 - 320)));
              border-radius: 100%;
              border: $card-border;
              overflow: hidden;
              object-fit: cover;
              border-radius: 100%;
              img {
                width: 100%;
              }
            }
          }
          .media-body {
            h3 {
              margin-bottom: 4px;
              font-weight: 600;
              font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
            }
            span {
              color: $content-color;
              font-size: calc(12px + (15 - 12) * ((100vw - 320px) / (1920 - 320)));
            }
          }
          > svg {
            width: 20px;
            height: 20px;
            stroke: $content-color;
            margin-left: auto;
            [dir="rtl"] & {
              margin-left: unset;
              margin-right: auto;
            }
          }
        }
      }
    }
  }
}
/// Blog List Style 4 Page Css End ///

/// Blog List Style 5 Page Css Start ///
.blog-style5 {
  @extend .blog-style4;
  .banner-box {
    margin-bottom: -3px;
    .banner {
      position: relative;
      > span {
        text-align: center;
        color: $white;
        position: absolute;
        top: calc(13px + (20 - 13) * ((100vw - 320px) / (1920 - 320)));
        right: calc(13px + (20 - 13) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;
        padding: 2px 8px;
        [dir="rtl"] & {
          left: calc(13px + (20 - 13) * ((100vw - 320px) / (1920 - 320)));
          right: unset;
        }
      }
    }
    .banner-content {
      .media {
        margin-top: 16px;
      }
      p {
        text-align: justify;
        word-break: normal;
        hyphens: auto;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  .comment-section {
    @include dashed-border-bottom();
    h4 {
      margin-top: -5px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .comment-box {
      &:not(:first-of-type) {
        border-top: 1px solid rgba($border-color, 0.1);
        padding-top: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
      }
      &:not(:last-of-type) {
        padding-bottom: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
      }
      .media {
        > div {
          &:first-of-type {
            width: calc(45px + (60 - 45) * ((100vw - 320px) / (1920 - 320)));
            height: calc(45px + (60 - 45) * ((100vw - 320px) / (1920 - 320)));
            object-fit: cover;
            img {
              border-radius: 100%;
              width: 100%;
            }
          }
        }
        .media-body {
          h5 {
            margin-bottom: 3px;
            font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
          }
          span {
            font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));
            color: $content-color;
          }
        }
      }
      p {
        color: $content-color;
        margin-top: 20px;
      }
      .replay {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > a {
          border-radius: 100px;
          padding: 7px 20px;
          background-color: $theme-yellow;
        }
        .like {
          display: flex;
          align-items: center;
          span {
            color: $content-color;
            display: flex;
            align-items: center;
            svg {
              width: 20px;
              height: 20px;
              &:first-of-type {
                margin-right: 8px;
                [dir="rtl"] & {
                  margin-left: 8px;
                  margin-right: unset;
                }
              }
            }
            &:not(:last-of-type) {
              margin-right: 20px;
              [dir="rtl"] & {
                margin-left: 20px;
                margin-right: unset;
              }
            }
          }
        }
      }
    }
  }
  .leave-reply {
    @include dashed-border-bottom();
    h4 {
      margin-top: -4px;
      font-weight: 600;
      margin-bottom: 3px;
    }
    p {
      color: $content-color;
    }
    .custom-form {
      button {
        box-shadow: none;
      }
    }
  }
  .related-articles {
    > h4 {
      margin-top: -4px;
      font-weight: 600;
    }
    .topic-box {
      margin-top: 15px;
      .topic {
        padding: 8px;
        border-radius: 15px;
        box-shadow: $box-shadow;
        border: $card-border;
        &:not(:first-of-type) {
          margin-top: calc(18px + (23 - 18) * ((100vw - 320px) / (1920 - 320)));
        }
        .media {
          position: relative;
          @include blog-list();
          @media only screen and (max-width: 375px) {
            align-items: flex-start;
          }
          .media-body {
            > div {
              @media only screen and (max-width: 375px) {
                flex-direction: column;
                align-items: flex-start;
              }
              span {
                &:first-of-type {
                  @media only screen and (max-width: 375px) {
                    margin-bottom: 6px;
                  }
                }
              }
            }
          }
          > div {
            &:first-of-type {
              border: unset;
              box-shadow: unset;
              padding: 0;
              height: 90px;
            }
          }
        }
      }
    }
  }
}
/// Blog List Style 5 Page Css End ///

/// Blog List Style 6 Page Css Start ///
.blog-style6 {
  @extend .blog-style5;
  .banner-slider {
    h2 {
      margin-bottom: 10px;
    }
    .blog-detail-slide {
      @include partial-slider();
      .slick-slide {
        margin-right: 0.4% !important;
        margin-left: 0.4% !important;
        transition: all 0.3s;
        @media only screen and (max-width: 355px) {
          margin-right: 0% !important;
          margin-left: 0% !important;
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
  }
  .ratio_45 {
    .bg-size {
      &:before {
        padding-top: 54%;
      }
    }
  }
  .banner-content {
    padding-top: 20px;
    .media {
      [dir="rtl"] & {
        flex-direction: row-reverse;
      }
      > div {
        &:first-of-type {
          width: calc(48px + (60 - 48) * ((100vw - 320px) / (1920 - 320)));
          height: calc(48px + (60 - 48) * ((100vw - 320px) / (1920 - 320)));
          border-radius: 100%;
          border: $card-border;
          overflow: hidden;
          object-fit: cover;
          border-radius: 100%;
          img {
            width: 100%;
          }
        }
      }
      .media-body {
        [dir="rtl"] & {
          text-align: right;
        }
        h3 {
          margin-bottom: 4px;
          font-weight: 600;
          font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
        }
        span {
          color: $content-color;
          font-size: calc(12px + (15 - 12) * ((100vw - 320px) / (1920 - 320)));
        }
      }
      > svg {
        width: 20px;
        height: 20px;
        stroke: $content-color;
        margin-left: auto;
        [dir="rtl"] & {
          margin-right: auto;
          margin-left: unset;
        }
      }
    }
    p {
      margin-top: 16px;
      margin-bottom: 16px;
      color: $content-color;
      text-align: justify;
      word-break: normal;
      hyphens: auto;
      &:last-of-type {
        margin-bottom: -6px;
      }
    }
  }
  .video-section {
    .videobox {
      position: relative;
      padding: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320)));
      border: $card-border;
      box-shadow: $box-shadow;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      .video-iframe {
        width: 100%;
        height: calc(170px + (260 - 170) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 15px;
        display: none;
      }
      .video-thumb {
        width: 100%;
        border-radius: 15px;
      }
      .play {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        cursor: pointer;
        height: 50px;
        background-image: $dark-gradient;
        svg {
          width: 25px;
          stroke: $white;
          height: 25px;
          margin-left: 4px;
        }
      }
    }
    > span {
      display: inline-block;
      color: $content-color;
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      margin-top: calc(17px + (23 - 17) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: 5px;
    }
    ul {
      margin-bottom: -6px;
      padding-left: 20px;
      padding-right: 20px;
      list-style: disc;
      li {
        color: $content-color;
        line-height: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }
}
/// Blog List Style 6 Page Css End ///
