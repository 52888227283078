/// Color Pallet ///
$theme-color: (
  white-light: $white-light,
  gray-light: $gray-light,
  purple: $theme-purple,
  orange: $theme-orange,
  dark-orange: $theme-dark-orange,
  green: $theme-green,
  yellow: $theme-yellow,
  yellow-dark: $theme-yellow-dark,
  blue: $theme-blue,
  danger: $theme-danger,
  success: $theme-success,
  title: $title-color,
);
@each $color, $hex in $theme-color {
  .stroke-#{$color} {
    stroke: $hex;
  }
  .fill-#{$color} {
    fill: $hex;
  }
}
$utilities: (
  0: 0,
  15: 0.15,
  30: 0.3,
  45: 0.45,
  50: 0.5,
  65: 0.65,
  70: 0.7,
  85: 0.85,
  100: 1,
);
@each $val, $value in $utilities {
  .opacity-#{$val} {
    opacity: $value;
  }
}

/// Bg Svg Shape Fill ///
@mixin bg-shape-fill {
  &:nth-of-type(5n + 1) {
    .bg-shape {
      fill: $theme-blue;
    }
  }

  &:nth-of-type(5n + 2) {
    .bg-shape {
      fill: $theme-yellow;
    }
  }

  &:nth-of-type(5n + 3) {
    .bg-shape {
      fill: $theme-green;
    }
  }

  &:nth-of-type(5n + 4) {
    .bg-shape {
      fill: $theme-purple;
    }
  }

  &:nth-of-type(5n + 5) {
    .bg-shape {
      fill: $theme-orange;
    }
  }
}
/// Rotate Bg Shape ///
@mixin rotate-bg-shape {
  &:nth-of-type(4n + 1) {
    .bg-shape {
      transform: rotateZ(-7deg);
      transform-origin: center;
    }
  }

  &:nth-of-type(4n + 2) {
    .bg-shape {
      top: -9px !important;
      transform: rotateZ(20deg);
      transform-origin: center;
    }
  }

  &:nth-of-type(4n + 3) {
    .bg-shape {
      top: -9px !important;
      transform: rotateZ(14deg);
      transform-origin: center;
    }
  }

  &:nth-of-type(4n + 4) {
    .bg-shape {
      transform: rotateZ(7deg);
      transform-origin: center;
    }
  }
}
/// Background Color ///
@mixin bg-color {
  &:nth-of-type(5n + 1) {
    background-color: $theme-blue;
  }

  &:nth-of-type(5n + 2) {
    background-color: $theme-yellow;
  }

  &:nth-of-type(5n + 3) {
    background-color: $theme-green;
  }

  &:nth-of-type(5n + 4) {
    background-color: $theme-purple;
  }

  &:nth-of-type(5n + 5) {
    background-color: $theme-orange;
  }
}
/// Dashed border Buttom ///
@mixin dashed-border-bottom {
  background-image: linear-gradient(to right, $content-color 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 14px 1px;
  background-repeat: repeat-x;
}

/// Banner ///
@mixin banner {
  border: $card-border;
  border-radius: 18px;
  box-shadow: $box-shadow;
  img {
    width: 100%;
  }
}
/// categores-slider ///
@mixin categores-slider {
  .categories-slide {
    .slide {
      position: relative;
      border: $card-border;
      padding: 14px;
      border-radius: 8px;
      width: 100%;
      text-align: center;
      img {
        position: relative;
        width: 50px;
        height: 50px;
        margin: 28px auto;
        z-index: 2;
      }
      h3 {
        font-size: 16px;
        font-weight: 400;
        margin-top: 34px;
      }
      svg {
        top: 38px;
        left: 50%;
        width: 110px;
        max-height: 110px;
        position: absolute;
      }
    }
    .slick-track {
      & > div {
        @include bg-shape-fill();
        &:nth-of-type(4n + 1) {
          .slide {
            .bg-shape {
              top: 27px;
              transform: rotateZ(20deg) translateX(-50%);
              transform-origin: center;
            }
          }
        }

        &:nth-of-type(4n + 2) {
          .slide {
            .bg-shape {
              top: 28px;
              transform: rotateZ(20deg) translateX(-50%);
              transform-origin: center;
            }
          }
        }

        &:nth-of-type(4n + 3) {
          .slide {
            .bg-shape {
              transform: rotateZ(14deg) translateX(-50%);
              transform-origin: center;
              top: 23px;
            }
          }
        }

        &:nth-of-type(4n + 4) {
          .slide {
            .bg-shape {
              transform: rotateZ(-3deg) translateX(-50%);
              transform-origin: center;
              top: 9px;
            }
          }
        }
      }
    }
  }
  .slick-slider {
    margin-left: -10px;
    margin-right: -10px;
    direction: ltr;
  }

  .slick-next {
    position: absolute;
    top: -35px;
    right: 10px;
    opacity: 0;
    z-index: 2;
    [dir="rtl"] & {
      right: unset;
      left: 10px;
    }
  }
  .slick-prev {
    position: absolute;
    top: -35px;
    right: 34px;
    left: unset;
    opacity: 0;
    z-index: 2;
    margin-right: 5px;
    [dir="rtl"] & {
      margin-left: 5px;
      margin-right: unset;
      left: 34px;
      right: unset;
    }
  }
  .slick-prev,
  .slick-next {
    background-image: $dark-gradient;
    &:before {
      color: $white;
    }
  }
  .slick-initialized {
    .slick-slide {
      padding: 0 10px;
    }
  }
  .arrow-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    button {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      position: relative !important;
      opacity: 1 !important;
      top: 0 !important;
      right: unset !important;
      left: unset !important;
      transform: translate(0, 0%) !important;
      svg {
        color: $white;
        width: 20px;
        height: 20px;
        stroke-width: 2.8px;
        [dir="rtl"] & {
          transform-origin: center;
          transform: rotate(180deg);
        }
      }
    }
    .slick-next,
    .slick-prev {
      &:before {
        display: none;
      }
    }
  }
}

/// Search Box ///
@mixin search-box {
  position: relative;
  input[type="search" i]:enabled:read-write:-webkit-any(:focus, :hover)::-webkit-search-cancel-button {
    display: none;
  }
  svg {
    position: absolute;
    width: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    height: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    top: 49%;
    cursor: pointer;
    transform: translateY(-50%);
  }
  .search {
    left: 16px;
  }
  .mic {
    right: 16px;
  }

  input {
    font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    padding: 10px 47px;
    border-radius: 8px;
    border: $card-border;
    box-shadow: $box-shadow;
  }
}

// Search with Side icon ///
@mixin search-side-icon {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  .search-box {
    width: 100%;
    @include search-box();
  }
  span {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 0 calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));
    background-image: $dark-gradient;
    border: $card-border;
    [dir="rtl"] & {
      margin-left: unset;
      margin-right: 20px;
    }
    img,
    svg {
      width: 24px;
      height: 24px;
      stroke: $white;
    }
  }
}

/// Start Box ///
@mixin start-box {
  display: flex;
  align-items: center;
  margin-top: 7px;
  svg {
    width: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    height: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    stroke: $theme-yellow-dark;
    &:not(:last-child) {
      margin-right: 2px;
      fill: $theme-yellow-dark;
      [dir="rtl"] & {
        margin-right: unset;
        margin-left: 2px;
      }
    }
  }
}

//// Order Summery ///
@mixin order-summery {
  ul {
    li {
      padding: 6px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        border: none;
        color: $content-color;
      }
      &:last-child {
        padding: 10px 0;
        margin-top: 13px;
        border-top: 1px solid rgba($border-color, 0.1);
        span {
          color: $title-color;
        }
      }
    }
  }
}

/// Scroll Nav Round ///
@mixin scroll-r-nav {
  ul {
    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: hidden;
    &:hover {
      overflow-x: scroll;
    }
    li {
      padding: 8px calc(25px + (35 - 25) * ((100vw - 320px) / (1920 - 320)));
      white-space: nowrap;
      border-radius: 100px;
      display: flex;
      align-items: center;
      line-height: 23px;
      font-size: calc(15px + (18 - 18) * ((100vw - 320px) / (1920 - 320)));
      &:not(:last-child) {
        margin-right: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
        [dir="rtl"] & {
          margin-left: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
          margin-right: unset;
        }
      }
      img,
      svg {
        width: calc(30px + (36 - 30) * ((100vw - 320px) / (1920 - 320)));
        height: calc(30px + (36 - 30) * ((100vw - 320px) / (1920 - 320)));
        margin-right: 5px;
        [dir="rtl"] & {
          margin-left: 10px;
          margin-right: unset;
        }
      }
    }
    @media only screen and (max-width: 767px) {
      overflow-x: auto;
    }
  }
}

/// Content Detail ///
@mixin content-detail {
  display: flex;
  align-items: center;
  span {
    display: flex;
    align-items: center;
    color: $content-color;
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
    svg {
      width: 16px;
      height: 16px;
      stroke: $content-color;
      margin-right: 8px;
      [dir="rtl"] & {
        margin-left: 8px;
        margin-right: unset;
      }
    }
    &:not(:last-child) {
      margin-right: 20px;
      [dir="rtl"] & {
        margin-left: 20px;
        margin-right: unset;
      }
      @media only screen and (max-width: 350px) {
        [dir="rtl"] & {
          margin-left: 0px;
          margin-right: unset;
        }
      }
    }
  }
}
/// Blog list ///
@mixin blog-list {
  > div {
    &:first-of-type {
      overflow: hidden;
      border: $card-border;
      box-shadow: $box-shadow;
      padding: 4px;
      border-radius: 6px;
      flex: 0 0 90px;
      img {
        width: 100%;
        border-radius: 6px;
        object-fit: cover;
      }
    }
  }
  .media-body {
    flex: 1 1 auto;
    h4 {
      font-weight: 600;
      margin-bottom: 6px;
      line-height: 19px;
    }
    p {
      margin-bottom: 6px;
    }
    > div {
      @include content-detail();
    }
    > span {
      top: 0;
      right: 0px;
      position: absolute;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      border-radius: 100px;
      background-image: $dark-gradient;
      [dir="rtl"] & {
        right: unset;
        left: 0px;
      }
      svg {
        width: 16px;
        height: 16px;
        stroke: $white;
        &.fill {
          fill: $white;
        }
      }
    }
  }
}

///Partial Slider ///
@mixin partial-slider {
  [dir="rtl"] & {
    direction: ltr;
  }
  .banner-box {
    text-align: center;
    @include banner();
    border: none;
    box-shadow: none;
    h2 {
      font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
      line-height: calc(25px + (32 - 25) * ((100vw - 320px) / (1920 - 320)));
    }
    p {
      line-height: calc(19px + (26 - 19) * ((100vw - 320px) / (1920 - 320)));
      margin: 0;
    }
  }
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .slick-slide {
    margin-right: 28px;
    margin-left: 28px;
    transition: all 0.3s;
    [dir="rtl"] & {
      float: left;
    }
    @media only screen and (max-width: 766px) {
      margin-right: 0.55%;
      margin-left: 0.55%;
    }
    @media only screen and (max-width: 525px) {
      margin-right: 0.65%;
      margin-left: 0.65%;
    }
    @media only screen and (max-width: 355px) {
      margin-right: 0%;
      margin-left: 0%;
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }
  .slick-center {
    transform: scale(1.2);
    @media only screen and (max-width: 355px) {
      transform: scale(1);
    }
  }
  .slick-list {
    padding-top: 6% !important;
    padding-bottom: 6% !important;
    margin-left: -15px;
    margin-right: -15px;
    @media only screen and (max-width: 355px) {
      margin-right: -8px;
      margin-left: -8px;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
