/*=====================
   2.5 Breadcum CSS 
=========================*/

/// Breadcum Style 1 Start ///
.breadcum-style1 {
    list-style-type: none;
    padding: 0;
    li {
        display: inline-block;
        position: relative;
        &:not(:first-of-type) {
            margin-left: 5px;
            left: 5px;
            [dir="rtl"] & {
                margin-left: unset;
                margin-right: 5px;
                left: unset;
                right: 5px;
            }
        }
        &:first-of-type {
            background-color: $white;
            border-left: 2px solid $border-color;
            left: 0px;
            box-sizing: content-box;
            &::before,
            &::after {
                left: 4px;
            }
            [dir="rtl"] & {
                left: unset;
                right: 0px;
                border-left: unset;
                border-right: 2px solid $border-color;
                &::before,
                &::after {
                    right: 4px;
                    left: unset;
                }
            }
        }
        a {
            display: inline-block;
            position: relative;
            line-height: 2.5;
            padding: 0 calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: $title-color;
            text-decoration: none;
        }
        &.active {
            &::before,
            &::after {
                background: $theme-yellow;
            }
        }
        &::before,
        &::after {
            content: "";
            position: absolute;
            left: -1px;
            height: 57%;
            width: calc(100% + 3px);
            background: $white;
            border-left: 2px solid $border-color;
            border-right: 2px solid $border-color;
            z-index: -2;
            [dir="rtl"] & {
                left: unset;
                right: -1px;
            }
        }

        &::before {
            top: -2px;
            transform: skew(30deg);
            border-top: 2px solid $border-color;
            [dir="rtl"] & {
                transform: skew(-30deg);
            }
        }

        &::after {
            bottom: -2px;
            transform: skew(-30deg);
            border-bottom: 2px solid $border-color;
            [dir="rtl"] & {
                transform: skew(30deg);
            }
        }
    }
}
/// Breadcum Style 1 End ///

/// Breadcum Style 2 Start ///
.breadcum-style2 {
    display: flex;
    align-items: center;
    li {
        padding: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320))) calc(10px + (22 - 10) * ((100vw - 320px) / (1920 - 320)));
        border: $card-border;
        box-shadow: $box-shadow;
        &:not(:first-of-type) {
            margin-left: 10px;
        }
        &.active {
            background-color: $theme-yellow;
        }
    }
}
/// Breadcum Style 2 End ///

/// Breadcum Style 3 Start ///
.breadcum-style3 {
    display: flex;
    align-items: center;
    border: $card-border;
    box-shadow: $box-shadow;
    padding: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320))) 0;
    li {
        position: relative;
        padding: 0 calc(15px + (22 - 15) * ((100vw - 320px) / (1920 - 320)));
        opacity: 0.5;
        &.active {
            opacity: 1;
        }
        svg {
            position: absolute;
            width: 16px;
            right: -8%;
            top: 50%;
            height: 16px;
            transform: translateY(-50%);
            [dir="rtl"] & {
                right: unset;
                left: -8%;
                transform-origin: center;
                transform: translateY(-50%) rotate(180deg);
            }
        }
        &:last-of-type {
            svg {
                display: none;
            }
        }
    }
}
/// Breadcum Style 3 End ///

/// Breadcum Style 3 Start ///
.breadcum-style4 {
    display: flex;
    align-items: center;
    border: $card-border;
    box-shadow: $box-shadow;
    padding: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320))) 0;
    li {
        position: relative;
        padding: 0 calc(15px + (22 - 15) * ((100vw - 320px) / (1920 - 320)));
        opacity: 0.5;
        display: flex;
        align-items: center;
        .home {
            margin-right: 5px;
            width: 20px;
            height: 20px;
            [dir="rtl"] & {
                margin-left: 5px;
                margin-right: unset;
            }
        }
        &.active {
            opacity: 1;
        }
        .arrow {
            position: absolute;
            width: 16px;
            right: -8%;
            top: 50%;
            height: 16px;
            transform: translateY(-50%);
            [dir="rtl"] & {
                right: unset;
                left: -8%;
                transform-origin: center;
                transform: translateY(-50%) rotate(180deg);
            }
        }
        &:last-of-type {
            .arrow {
                display: none;
            }
        }
    }
}
/// Breadcum Style 3 End ///

/// Breadcum Style 5 Start ///
.breadcum-style5 {
    padding: 14px 40px;
    background-image: $dark-gradient;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: $btn-shadow;
    @media only screen and (max-width: 424px) {
        padding: 14px 20px;
    }
    li {
        position: relative;
        svg {
            width: 24px;
            height: 24px;
            stroke: $white;
            opacity: 0.5;
            &.active {
                opacity: 1;
            }
        }
        &:not(:last-of-type) {
            &::after {
                content: "";
                position: absolute;
                @include dashed-border-bottom();
                opacity: 0.5;
                background-image: linear-gradient(to right, $white 70%, rgba($black, 0) 0%);
                background-position: center;
                width: 186px;
                height: 100%;
                margin-left: 11px;
                [dir="rtl"] & {
                    margin-left: unset;
                    margin-right: 11px;
                }
                @media only screen and (max-width: 767px) {
                    width: calc(48px + (807 - 48) * ((100vw - 320px) / (1920 - 320)));
                }
                @media only screen and (max-width: 424px) {
                    width: calc(65px + (807 - 65) * ((100vw - 320px) / (1920 - 320))) !important;
                }
            }
        }
        &.active {
            &::after {
                opacity: 1;
            }
        }
    }
}
/// Breadcum Style 5 End ///

/// Breadcum Style 6 Start ///
.breadcum-style6 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;

    li {
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;
        color: $white;
        border-radius: 100px;
        border: $card-border;
        width: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
        height: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
        background-color: $white;
        color: $title-color;
        .tag {
            position: absolute;
            top: calc(42px + (52 - 42) * ((100vw - 320px) / (1920 - 320)));
            color: $title-color;
        }
        &::after {
            content: "";
            position: absolute;
            height: 7px;
            left: calc(38px + (48 - 38) * ((100vw - 320px) / (1920 - 320)));
            z-index: 1;
            width: 228px;
            border: $card-border;
            [dir="rtl"] & {
                right: calc(38px + (48 - 38) * ((100vw - 320px) / (1920 - 320)));
                left: unset;
            }
            @media only screen and (max-width: 767px) {
                width: calc(90px + (895 - 90) * ((100vw - 320px) / (1920 - 320)));
            }
        }
        &:last-of-type {
            &::after {
                display: none;
            }
        }
        &.active {
            background-image: $dark-gradient;
            color: $white;
            &::after {
                background-image: $dark-gradient;
            }
        }
    }
}
/// Breadcum Style 6 End ///
